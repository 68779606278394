import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    bookingConfig: null,
    error: null,
};

export const roomBookingConfigSlice = createSlice({
    name: "roomBookingConfig",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.loading = true;
            state.bookingConfig = null;
            state.error = null;
        },

        bookingConfigFetched: (state, action) => {
            state.loading = false;
            state.bookingConfig = action.payload;
        },

        resetState: (state, action) => {
            state.loading = false;
            state.error = null;
            state.bookingConfig = null;
        },
    }
});  

/* eslint-disable no-restricted-imports */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from '@material-ui/core/FormGroup';
import SettingsIcon from '@material-ui/icons/Settings';
import { BaseExpansionPanel } from "./BaseExpansionPanel";

const useStyles = makeStyles(theme => ({
    settingsRoot: {
        width: '100%',
        marginLeft: theme.spacing(3),
    },
    settingsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
}));

export function SettingsPanel({ children }) {
    const classes = useStyles();

    return (
        <BaseExpansionPanel icon={<SettingsIcon />}
            title="SETTINGS_PANEL.TITLE">
            <div className={classes.settingsRoot}>
                <div className="row">
                    <div className={classes.settingsContainer}>
                        <FormGroup row>
                            {children}
                        </FormGroup>
                    </div>
                </div>
            </div>
        </BaseExpansionPanel>
    );
}

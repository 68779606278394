/* eslint-disable no-restricted-imports */
import React from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    filterButton: {
        minWidth: 80,
    },
}));

export function SearchButton({ icon, label, type, disabled, onClick }) {
    const classes = useStyles();

    return (
        <Button className={classes.filterButton}
            fullWidth={true}
            variant="contained"
            color="secondary"
            startIcon={icon}
            type={(type) ? type : 'button'}
            disabled={disabled}
            onClick={onClick}>
            <FormattedMessage id={label} />
        </Button>
    );

}

import * as auth from "./../app/modules/Auth/_redux/authRedux";
import * as services from "./../app/modules/Auth/_redux/authCrud";
const EXPIRE_TOKEN_MESSAGE = "Expired JWT Token";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      return config;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.response.use((response) => {
    return response
  },
    async (responseError) => {
      const originalRequest = responseError.config;
      const { code, message } = responseError.response.data;
      if (code === 401 && message === EXPIRE_TOKEN_MESSAGE && !originalRequest._retry) {
        originalRequest._retry = true;
        const {
          auth: { refresh_token }
        } = store.getState();
        try {
          const response = await services.refreshToken(refresh_token);
          const newToken = response.data.token;
          const newRefreshToken = response.data.refresh_token;
          store.dispatch(auth.actions.refreshToken(newToken, newRefreshToken));
          return axios(originalRequest);
        } catch (error) {
          store.dispatch(auth.actions.logout());
        }
      }
      else{
        return Promise.reject({... responseError}); 
      }
    });
}

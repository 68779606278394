import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    chains: [],
    chain: null,
    error: null
};

export const chainSlice = createSlice({
    name: "chain",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
        },

        chainsFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.chains = action.payload.chains;
        },

        chainFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.chain = action.payload.chain;
        }

    }

});

import { createSlice } from "@reduxjs/toolkit";
import { getConfigTemplatesMap } from "./configTemplatesMap";


const initialState = {
    actionsLoading: false,
    error: null,
    configTemplates: null,
    configTemplatesLoaded: false
};

export const configTemplateSlice = createSlice({
    name: "configTemplates",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.actionsLoading = false;
            state.configTemplatesLoaded = false;
            state.configTemplates = null;
        },

        startCall: (state, action) => {
            state.actionsLoading = true;
            state.configTemplatesLoaded = false;
        },

        // load  ConfigTemplates
        frontConfigTemplatesFetch: (state, action) => {
            const { configTemplates, totalCount } = getConfigTemplatesMap(action.payload);
            state.actionsLoading = false;
            state.error = null;
            state.configTemplates = configTemplates;
            state.configTemplatesItems = totalCount;
            state.configTemplatesLoaded = true;

        }
    }
});

/* eslint-disable no-restricted-imports */
import React from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from '@material-ui/core/FormHelperText';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%',
    }
}));

FormRichEditor.defaultProps = {
    label: '',
    required: false,
    error: false,
    errorMessage: ''
};

export function FormRichEditor(
    {
        label,
        required,
        editorState,
        onChange,
        error,
        errorMessage,
    }
) {
    const classes = useStyles();

    const handlePastedText = (text) => {
        const contentBlock = htmlToDraft(text);
        onChange(
            EditorState.createWithContent(
                ContentState.createFromBlockArray(contentBlock.contentBlocks)
            )
        );
        return true;
    };

    const editorStyle = {
        width: "100%",
        height: "400px",
    };

    return (
        <FormControl className={classes.formControl} required={required}
            error={error}>
            {(label) && (
                <FormLabel>
                    <FormattedMessage id={label} />
                </FormLabel>
            )}
            <Paper variant='outlined'>
                <Editor editorStyle={editorStyle}
                    toolbarClassName="rdw-editor-toolbar"
                    wrapperClassName="rdw-editor-wrapper"
                    editorClassName="rdw-editor-main"
                    toolbar={{
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                    }}
                    editorState={editorState}
                    onEditorStateChange={onChange}
                    handlePastedText={handlePastedText} />
            </Paper>
            <FormHelperText >
                {(errorMessage) && (
                    <FormattedMessage id={errorMessage} />
                )}
            </FormHelperText>
        </FormControl>
    );

}

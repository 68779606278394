/* eslint-disable no-restricted-imports */

import React from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
    heading: {
        fontSize: theme.typography.pxToRem(16),
        flexBasis: '33.33%',
        flexShrink: 0,
    }
}));

FormExpansionPanel.defaultProps = {
    id: "",
    label: "",
    expansible: true,
    expanded: false,
}

export function FormExpansionPanel({ id, label, expansible, expanded, children, onChange }) {
    const classes = useStyles();

    return (
        <ExpansionPanel expanded={(expansible) ? expanded : true} onChange={onChange}>
            <ExpansionPanelSummary expandIcon={(expansible) ? <ExpandMoreIcon /> : undefined} id={id}>
                <Typography className={classes.heading}>
                    <FormattedMessage id={label} />
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                {children}
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );

}

/* eslint-disable no-restricted-imports */
import React from "react";
import { FormattedMessage } from "react-intl";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import SubmitIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/HighlightOff';

export function ConfirmDialog({ title, content, open, onConfirmClick, onCloseClick }) {

    return (
        <Dialog open={open}
            onClose={onCloseClick}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description" >
            {(title) && (
                <DialogTitle id="confirm-dialog-title">
                    <FormattedMessage id={title} />
                </DialogTitle>
            )}
            {(content) && (
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        <FormattedMessage id={content} />
                    </DialogContentText>
                </DialogContent>
            )}
            <DialogActions>
                <Button variant="contained"
                    color="secondary"
                    startIcon={<CloseIcon />}
                    onClick={onCloseClick}>
                    <FormattedMessage id={"CONFIRM_DIALOG.ACTION.CANCEL"} />
                </Button>
                <Button variant="contained"
                    color="secondary"
                    autoFocus={true}
                    startIcon={<SubmitIcon />}
                    onClick={onConfirmClick} >
                    <FormattedMessage id={"CONFIRM_DIALOG.ACTION.CONFIRM"} />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

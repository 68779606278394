/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { toAbsoluteUrl } from "../../../../_helpers";
import { displayRoles } from "../../../../../app/shared/constants";

export function QuickUser() {
  const { user } = useSelector(state => state.auth);

  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-10">
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">
          <FormattedMessage id="USER.PROFILE_PANEL.TITLE" />
        </h3>
        <a href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close" >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5" >
        <div className="d-flex align-items-center mt-5">
          <div className="symbol symbol-100 mr-5" >
            <div className="symbol-label" style={{
              backgroundImage: `url(${toAbsoluteUrl((user.pic) ? user.pic : "/media/users/default.jpg")})`
            }} />
          </div>
          <div className="d-flex flex-column">
            <div className="font-weight-bold font-size-h5 text-dark-75">
              {user.userName}
            </div>
            {user.roles.length > 0 && (user.roles.map((role, index) => {
              return (
                <div className="text-muted mt-1" key={index}>
                  {displayRoles[role]}
                </div>
              );
            }))}
            <div className="text-muted p-0 pb-1">
              {user.email}
            </div>
            <Link to="/change-password"
              className="text-dark-50 text-hover-primary my-3 mr-2" >
              <FormattedMessage id="ACCOUNT.PASSWORD.CHANGE_PASSWORD.LINK.CHANGE_PASSWORD" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

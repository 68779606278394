/* eslint-disable no-restricted-imports */
import React from "react";
import { FormattedMessage } from "react-intl";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/HighlightOff';

export function ConfirmCancelDialog(
    {
        open,
        fees,
        today,
        showFees,
        onCancelWithFeesConfirmClick,
        onCancelWithoutFeesConfirmClick,
        onCloseClick
    }
) {
    return (
        <Dialog open={open}
            onClose={onCloseClick}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description" >

            <DialogTitle id="confirm-dialog-title">
                <FormattedMessage id="BOOKING.CONFIRM_DELETE_DIALOG.TITLE" />
            </DialogTitle>

            <DialogContent>
                {(fees) && (
                    <DialogContentText id="confirm-dialog-description">
                        <FormattedMessage id="BOOKING.CONFIRM_DELETE_DIALOG.FEES_DETAILS" values={{ fees: fees }} />
                    </DialogContentText>
                )}

                <DialogContentText id="confirm-dialog-description">
                    <FormattedMessage id="BOOKING.CONFIRM_DELETE_DIALOG.CONTENT" />
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                {showFees && today > 0 && <Button variant="contained"
                    color="secondary"
                    autoFocus={true}
                    onClick={onCancelWithFeesConfirmClick} >
                    <FormattedMessage id={"CONFIRM_CANCEL_DIALOG.ACTION.CANCEL_WITH_FEES"} />
                </Button>}

                <Button variant="contained"
                    color="secondary"
                    onClick={onCancelWithoutFeesConfirmClick} >
                    <FormattedMessage id={"CONFIRM_CANCEL_DIALOG.ACTION.CANCEL_WITHOUT_FEES"} />
                </Button>

                <Button variant="outlined"
                    color="secondary"
                    startIcon={<CloseIcon />}
                    onClick={onCloseClick}>
                    <FormattedMessage id={"CONFIRM_CANCEL_DIALOG.ACTION.CLOSE"} />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

import { createSlice } from "@reduxjs/toolkit";
import { callTypes } from "../../../../shared/constants";

const initialState = {
    listLoading: false,
    actionsLoading: false,
    availabilities: [],
    lastError: null
};

export const roomAvailabilitySlice = createSlice({
    name: "roomAvailability",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },

        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },

        availabilitiesUploaded: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            action.payload.availabilities.map((availability) => state.availabilities.push(availability));
        },

        availabilitiesFetched: (state, action) => {
            state.listLoading = false;
            state.error = null;
            state.availabilities = action.payload.availabilities;
        },
    }
});

import { createSlice } from "@reduxjs/toolkit";
import { MESSAGE_TYPES } from "../../../../shared/constants";

const initialState = {
    isLoading: false,
    availabilityRecords: [],
    roomTypes: [],
    needRefresh: false,
    successfulUpdate: false,
    messages: [],
};
const { SUCCESS, ERROR } = MESSAGE_TYPES;

export const avaDayViewSlice = createSlice({
    name: "RoomAvailabilityDayView",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.messages.push({ id: action.payload.clientMessage, type: ERROR });
            state.messages.push({ id: action.payload.additionalMessage, type: ERROR });
            state.isLoading = false;
            state.needRefresh = true;
            state.successfulUpdate = false
            state.availabilityRecords = [];
        },
        startCall: (state) => {
            state.isLoading = true;
            state.needRefresh = false;
            state.successfulUpdate = false;
        },
        finishCall: (state) => {
            state.isLoading = false;
        },
        recordsFetched: (state, action) => {
            state.isLoading = false;
            state.availabilityRecords = action.payload.availability;
        },
        recordUpdated: (state, action) => {
            state.isLoading = false;
            state.needRefresh = true;
            state.successfulUpdate = true;
            state.messages.push({ id: action.payload.message, type: SUCCESS });
        },
        roomTypesFetched: (state, action) => {
            const { roomTypes } = action.payload;
            state.isLoading = false;
            state.roomTypes = roomTypes;
        },
        removeMessages: (state) => {
            state.messages = [];
        },
        resetState: (state, action) => {
            state.isLoading = false;
            state.availabilityRecords = [];
            state.roomTypes = [];
            state.needRefresh = false;
            state.successfulUpdate = false;
        }
    }
});

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    roomTypeClassifications: [],
    roomTypeClassification: null,
    error: null
};

export const roomTypeClassificationSlice = createSlice({
    name: "roomTypeClassification",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
        },

        roomTypeClasificationsFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.roomTypeClassifications = action.payload.roomTypeClassifications;
        },

        roomTypeClasificationFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.roomTypeClassification = action.payload.roomTypeClassification;
        }

    }
});
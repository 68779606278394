import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
}));

export function PageContainer({ children }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {children}
        </div>
    );
}

/* eslint-disable no-restricted-imports */
import React from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from '@material-ui/core/FormGroup';
import { FormSwitch } from "./FormSwitch";

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%',
        marginLeft: theme.spacing(2),
    }
}));

FormSwitchGroup.defaultProps = {
    label: "",
    options: [],
    disabled: false,
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12
};

export function FormSwitchGroup({ label, options, disabled, xs, sm, md, lg, xl, onChange }) {
    const classes = useStyles();

    return (
        <FormControl className={classes.formControl} color="secondary" >
            {(label) && (
                <FormLabel>
                    <FormattedMessage id={label} />
                </FormLabel>
            )}
            <FormGroup>
                <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                    {options.map((option) => {
                        return (
                            <Grid key={option.id} item xs={xs} sm={sm} md={md} lg={lg} xl={xl} >
                                <FormSwitch name={option.name}
                                    checked={option.checked}
                                    disabled={disabled}
                                    label={option.label}
                                    onChange={onChange} />
                            </Grid>
                        );
                    })}
                </Grid>
            </FormGroup>
        </FormControl>
    );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    error: null,
    billCodePromo: null,
};

export const billCodePromoSlice = createSlice({
    name: "billCodePromo",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.loading = false;
            state.error = { ...action.payload.error };
        },

        startCall: (state, action) => {
            state.loading = true;
            state.error = null;
            state.billCodePromo = null;
        },

        billCodePromoFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.billCodePromo = action.payload;
        },
        resetState: (state) => {
            state.loading = false;
            state.error = null;
            state.billCodePromo = null;
        },
    }
});

/* eslint-disable no-restricted-imports */
import React from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { buttonType } from "../../../_helpers/FormHelpers";

const useStyles = makeStyles(theme => ({
    formButton: {
        minWidth: 80,
    },
}));

FormButton.defaultProps = {
    type: buttonType.BUTTON,
    variant: 'contained',
    disabled: false,
    endIcon: undefined
};

export function FormButton({ type, variant, icon, endIcon, label, disabled, onClick }) {
    const classes = useStyles();

    return (
        <Button className={classes.formButton}
            fullWidth={true}
            color="secondary"
            type={type}
            variant={variant}
            startIcon={icon}
            endIcon={endIcon}
            disabled={disabled}
            onClick={onClick}>
            <FormattedMessage id={label} />
        </Button>
    );
}

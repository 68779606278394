import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    destinations: [],
    destination: null,
    error: null
};

export const destinationSlice = createSlice({
    name: "destination",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.loading = true;
            state.error = null;
        },

        destinationsFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.destinations = action.payload.destinations;
        },

        destinationFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.destination = action.payload.destination;
        }

    }

});

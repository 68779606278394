import axios from "axios";
import { API_SERVER_URL } from "../../../shared/constants";

const LOGIN_URL = API_SERVER_URL + "authentication_token";
const REQUEST_PASSWORD_URL = API_SERVER_URL + "api/v1/users/recuperapassword";
const REQUEST_RESET_PASSWORD_URL = API_SERVER_URL + "api/v1/users/resetpassword";
const ME_URL = API_SERVER_URL + "api/v1/users/me";
const REFRESH_TOKEN_URL = API_SERVER_URL + "refresh_token";

export function login(email, password) {

  return axios.post(LOGIN_URL, { email, password });
}

export function resetPassword(id, code, newPassword) {
    const request = {
        code: code,
        newPassword: newPassword
    }
   return axios.put(API_SERVER_URL + "api/v1/users/changepasswordlogout/"+ id, request);;
}


export function requestPassword(email) {
  return axios.get(REQUEST_PASSWORD_URL+"?email="+email);
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function refreshToken(refresh_token) {
  return axios.get(`${REFRESH_TOKEN_URL}?refresh_token=${refresh_token}`);
}

/* eslint-disable no-restricted-imports */
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles(theme => ({
    appBar: {
        zIndex: 0,
    },
    paper: {
        width: '100%',
        overflowX: 'auto',
    }
}));

export function TabsPanel({ tabs, activeTab, onChangeActiveTab, children }) {
    const classes = useStyles();

    return (
        <Paper className={classes.paper} variant="outlined">
            <AppBar className={classes.appBar} position="static" color="default" elevation={0}>
                <Tabs value={activeTab}
                    onChange={onChangeActiveTab}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="scrollable"
                    scrollButtons="on">
                    {tabs.map((tab, i) => {
                        return (
                            <Tab key={`scrollable-tab-${i}`}
                                label={tab.label}
                                value={tab.value} />
                        );
                    })}
                </Tabs>
            </AppBar>
            <Card>
                <CardContent>
                    {children}
                </CardContent>
            </Card>
        </Paper>
    );
}

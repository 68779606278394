import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    success: null,
    loading: false,
    error: null,
};

export const passwordSlice = createSlice({
    name: "password",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.success = null;
            state.loading = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.success = null;
            state.loading = true;
        },

        clearSuccessMessage(state, action) {
            state.success = null;
        },

        clearErrorMessage(state, action) {
            state.error = null;
        },

        passwordChanged: (state, action) => {
            state.error = null;
            state.success = "ACCOUNT.PASSWORD.ACTIONS.SUCCESS.CHANGE_PASSWORD";
            state.loading = false;
        }

    }
});

export const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL;
export const WEB_EXTRANET_URL = process.env.REACT_APP_EXTRANET_WEB;
export const BILLS_SERVER = process.env.REACT_APP_BILLS_SERVER;
export const BYHOURS_WEB = process.env.REACT_APP_BYHOURS_WEB;
export const MAX_STARS = Number(process.env.REACT_APP_MAX_STARS);
export const MIN_VALID_ROOM_PRICE = 14;
export const ADD_ANALYTICS = process.env.REACT_APP_ADD_ANALYTICS;
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
export const AWS_BASE_URL = process.env.REACT_APP_AWS_BASE_URL;


export const ROLES = {
    SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
    COMPANY_MANAGER: 'ROLE_COMPANY_MANAGER',
    HOTEL_MANAGER: 'ROLE_HOTEL_MANAGER'
};

export const displayRoles = {
    ROLE_SUPER_ADMIN: 'Super Admin',
    ROLE_COMPANY_MANAGER: 'Company Manager',
    ROLE_HOTEL_MANAGER: 'Hotel Manager'
};

export const ROLES_ID = {
    ROLE_SUPER_ADMIN: 1,
    ROLE_COMPANY_MANAGER: 2,
    ROLE_HOTEL_MANAGER: 3
};

export const ROLES_OPTIONS = [
    { label: "Super Admin", value: 'ROLE_SUPER_ADMIN' },
    { label: "Company Manager", value: 'ROLE_COMPANY_MANAGER' },
    { label: "Hotel Manager", value: 'ROLE_HOTEL_MANAGER' },
];

export const CHANNEL_BYHOURS = 100;

export const CHANNELS = [
    { label: 'CHANNELS.LABELS.BYHOUR', value: CHANNEL_BYHOURS },
    { label: 'CHANNELS.LABELS.DIRECT', value: 4 }
];

export const CHANNELS_MEETING_ROOM_ID = {
    CHANNEL_BYHOURS: 5,
    CHANNEL_DIRECT: 6,
};

export const callTypes = {
    list: "list",
    action: "action"
};

export const priceTypes = {
    PERCENTAGE_PRICE: 1,
    LINEAL_PRICE: 2
}

export const PACK_TYPES = {
    THREE_HOURS: 3,
    SIX_HOURS: 6,
    TWELVE_HOURS: 24,
}

export const RATE_TYPES_VALUES = {
    NET_PRICE: 1,
    PER_PERSON: 3
}

export const RATE_TYPES = [
    { label: "MEETING_ROOMS.RATE_TYPE.NET_PRICE", value: 1 },
    { label: "MEETING_ROOMS.RATE_TYPE.PER_PERSON", value: 3 }
];

export const ROOM_PACKS = {
    MORNING: 1,
    AFTERNOON: 2,
    DAY: 3,
    OPEN: 4,
    RESERVED: 5
}

export const MESSAGE_TYPES = {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    INFO: 'info'
}

export const LOAD_MODES = {
    APPEND: 1,
    REPLACE: 2,
}

export const DATE_FORMAT = "dd-MM-yyyy";
export const MOMENT_DATE_FORMAT = "DD-MM-YYYY";
export const MOMENT_DATE_FORMAT_REQUEST = "YYYY-MM-DD";
export const MOMENT_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const INVALID_DATE = "Invalid Date";

export const WEEK_DAYS = [
    { id: 1, name: "WEEK_DAYS.MONDAY", excluded: false },
    { id: 2, name: "WEEK_DAYS.TUESDAY", excluded: false },
    { id: 3, name: "WEEK_DAYS.WEDNESDAY", excluded: false },
    { id: 4, name: "WEEK_DAYS.THURSDAY", excluded: false },
    { id: 5, name: "WEEK_DAYS.FRIDAY", excluded: false },
    { id: 6, name: "WEEK_DAYS.SATURDAY", excluded: false },
    { id: 7, name: "WEEK_DAYS.SUNDAY", excluded: false }];

export const YES_NO_OPTIONS = [
    { label: "GENERAL.YES", value: true },
    { label: "GENERAL.NO", value: false }
];

export const EMAIL_TYPES = [
    { label: "GENERAL.EMAIL_TEMPLATES.ROOM.CONFIRMATION", value: 1 },
    { label: "GENERAL.EMAIL_TEMPLATES.ROOM.CANCELLATION", value: 2 },
    { label: "GENERAL.EMAIL_TEMPLATES.MEETING_ROOM.CONFIRMATION", value: 3 },
    { label: "GENERAL.EMAIL_TEMPLATES.MEETING_ROOM.CANCELLATION", value: 4 },
];

export const TRANSLATIONS_TABS = [
    { label: "Español", value: "spa" },
    { label: "English", value: "eng" },
    { label: "Deutsch", value: "deu" },
    { label: "Français", value: "fra" },
    { label: "Italiano", value: "ita" },
    { label: "Português", value: "por" },
    { label: "Nederlands", value: "nld" }
];

export const CURRENCIES = [
    { "id": "AED", "symbol": "AED" },
    { "id": "ARS", "symbol": "AR$" },
    { "id": "BRL", "symbol": "R$" },
    { "id": "CHF", "symbol": "CHF" },
    { "id": "CLP", "symbol": "CLP" },
    { "id": "COP", "symbol": "COP" },
    { "id": "EUR", "symbol": "€" },
    { "id": "GBP", "symbol": "£" },
    { "id": "MXN", "symbol": "MXN" },
    { "id": "TRY", "symbol": "TL" },
    { "id": "USD", "symbol": "$" }];

export const CHANNEL_WEB = "BH Rooms Web";
export const CHANNEL_APP = "BH Rooms App";

/* eslint-disable no-restricted-imports */
import React from "react";
import { injectIntl } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    detailCard: {
        width: '100%',
    },
    detailMedia: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        maxWidth: 350,
        height: 200,
    },
    gallery: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    thumbnailCard: {
        width: '100%',
    },
    thumbnailMedia: {
        maxWidth: 160,
        height: 80,
    },
}));

ImageGallery.defaultProps = {
    disableSelectFeaturedImage: false
};

function ImageGallery(
    {
        intl,
        images,
        selectedImage,
        onImageClick,
        disableSelectFeaturedImage,
        onChangeFeaturedImage
    }
) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container >
                <Grid item xs={12}>
                    <Card className={classes.detailCard} >
                        <Collapse unmountOnExit
                            in={selectedImage.id !== -1}
                            timeout="auto" >
                            <CardMedia className={classes.detailMedia}
                                image={selectedImage.detailURL} />
                        </Collapse>
                    </Card>
                </Grid>
                {(images) && (
                    images.map((image) => {
                        return (
                            <Grid item xs={6} lg={2} md={3} sm={4} key={image.id} >
                                <div className={classes.gallery}>
                                    <Card className={classes.thumbnailCard}
                                        raised={image.id === selectedImage.id} >
                                        <CardActionArea onClick={() => onImageClick(image)} >
                                            <CardMedia className={classes.thumbnailMedia}
                                                image={image.thumbnailURL} />
                                        </CardActionArea>
                                        {(!disableSelectFeaturedImage) && (
                                            <CardActions>
                                                <FormControlLabel
                                                    control={
                                                        <Switch checked={image.featured}
                                                            onChange={() => onChangeFeaturedImage(image)}
                                                            name={`featured-image-${image.id}`}
                                                            color="secondary" />}
                                                    label={intl.formatMessage({ id: "CONTROL.IMAGE_GALLERY.FEATURED" })} />
                                            </CardActions>
                                        )}
                                    </Card>
                                </div>
                            </Grid>
                        );
                    }))}
            </Grid>
        </div>
    );
}

export default injectIntl(ImageGallery);

/* eslint-disable no-restricted-imports */
import React from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import BoxIcon from '@material-ui/icons/Stop';
import Calendar from 'rc-year-calendar';

const useStyles = makeStyles(theme => ({
    paper: {
        width: '100%',
        overflowX: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    calendar: {
        margin: theme.spacing(2),
    },
    footer: {
        margin: theme.spacing(2),
    },
    caption: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    }
}));

export function YearCalendar({
    enableRangeSelection,
    language,
    dataSource,
    onDayClick,
    onRangeSelected,
    onYearChanged,
    caption }) {
    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <div className={classes.calendar}>
                <Calendar
                    style={`background`}
                    enableRangeSelection={enableRangeSelection}
                    language={language}
                    dataSource={dataSource}
                    onDayClick={onDayClick}
                    onRangeSelected={onRangeSelected}
                    onYearChanged={onYearChanged} />
            </div>
            <div className={classes.footer}>
                <Divider />
                <div className={classes.caption}>
                    <Typography variant="h6" className={classes.title}>
                        <FormattedMessage id="UPLOAD_AVAILABILITY.CAPTION.TITLE" />
                    </Typography>
                    <List dense={true}>
                        {caption.map((caption) => {
                            return (
                                <ListItem key={caption.description}>
                                    <BoxIcon style={{ color: caption.color }} />
                                    <ListItemText primary={<FormattedMessage id={caption.description} />} />
                                </ListItem>
                            );
                        })}
                    </List>
                </div>
            </div>
        </Paper>
    );
}

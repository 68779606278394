/* eslint-disable no-restricted-imports */
import React from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/styles";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from "@material-ui/core/CardContent";
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    card: {
        minWidth: 140,
        maxWidth: 160,
    },
    cardMedia: {
        height: 100,
        width: 110,
        margin: 'auto'
    },
});

export function FormDispositionPanel(
    {
        label,
        dispositions,
        error,
        onStatusChange,
        onCapacityChange
    }
) {
    const classes = useStyles();

    return (
        <FormControl className={classes.root} color="secondary">
            <FormLabel>
                <FormattedMessage id={label} />
            </FormLabel>
            <FormGroup>
                <Card variant="outlined">
                    <CardContent>
                        <Grid container justify="flex-start" alignItems="flex-start" spacing={1}>
                            {((dispositions) && (dispositions.length > 0)) && (dispositions.map((disposition, index) => {
                                const { capacity } = ((error) && (error.length > index) && (error[index])) ? error[index] : {};
                                return (
                                    <Grid item xs={6} sm={4} md={3} lg={2} key={disposition.id}>
                                        <Card className={classes.card} variant="outlined">
                                            <CardHeader title={disposition.name} />
                                            <CardMedia className={classes.cardMedia} image={disposition.image} />
                                            <CardActions>
                                                <Grid container justify="flex-start" alignItems="flex-start" spacing={1}>
                                                    <Grid item xs={12}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch id={`disposition-${disposition.id}-status`}
                                                                    name={`disposition-${disposition.id}-status`}
                                                                    checked={disposition.active}
                                                                    color="secondary"
                                                                    onChange={(event) => onStatusChange(event, disposition)} />
                                                            }
                                                            label={disposition.active
                                                                ? <FormattedMessage id={"CONTROL.DISPOSITION_PANEL.ENABLED"} />
                                                                : <FormattedMessage id={"CONTROL.DISPOSITION_PANEL.DISABLED"} />
                                                            } />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField id={`disposition-${disposition.id}-capacity`}
                                                            name={`disposition-${disposition.id}-capacity`}
                                                            label={<FormattedMessage id={"CONTROL.DISPOSITION_PANEL.CAPACITY"} />}
                                                            value={disposition.capacity}
                                                            required={disposition.active}
                                                            disabled={!disposition.active}
                                                            fullWidth={true}
                                                            variant="outlined"
                                                            color="secondary"
                                                            error={(capacity !== undefined)}
                                                            helperText={(capacity) && <FormattedMessage id={capacity} />}
                                                            onChange={(event) => onCapacityChange(event, disposition)} />
                                                    </Grid>
                                                </Grid>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                );
                            }))}
                        </Grid>
                    </CardContent>
                </Card>
            </FormGroup>
        </FormControl>
    );
}

import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";
import { userHasRole } from "../../../../app/_helpers";
import { ROLES } from "../../../../app/shared/constants";

export function Brand() {
  const { user } = useSelector(state => state.auth);
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo()
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Brand */}
      <div className={`brand flex-column-auto ${layoutProps.brandClasses}`}
        id="kt_brand" >

        {/* begin::Logo */}
        {(userHasRole(user.roles, ROLES.SUPER_ADMIN)) && (
          <React.Fragment>
            <Link to="" className="brand-logo">
              <img alt="logo" src={layoutProps.headerLogo} />
            </Link>
          </React.Fragment>
        )}

        {(userHasRole(user.roles, ROLES.COMPANY_MANAGER)) && (
          <React.Fragment>
            <Link to="" className="brand-logo">
              <span className="svg-icon m-1">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Hotels1.svg")} />
              </span>
              <span className="font-weight-bold font-size-h5 m-1">
                {user.chain}
              </span>
            </Link>
          </React.Fragment>
        )}

        {(userHasRole(user.roles, ROLES.HOTEL_MANAGER)) && (
          <React.Fragment>
            <Link to="" className="brand-logo">
              <span className="svg-icon m-1">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Hotels1.svg")} />
              </span>
              <span className="font-weight-bold font-size-h5 m-1">
                {user.hotel}
              </span>
            </Link>
          </React.Fragment>
        )}
        {/* end::Logo */}

        {layoutProps.asideSelfMinimizeToggle && (
          <React.Fragment>
            {/* begin::Toggle */}
            <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
              <span className="svg-icon svg-icon-xl">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")} />
              </span>
            </button>
            {/* end::Toggle */}
          </React.Fragment>
        )}

      </div>
      {/* end::Brand */}
    </>
  );
}

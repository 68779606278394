import * as accounService from "./passwordService";
import { passwordSlice } from "./passwordSlice";

const { actions } = passwordSlice;

export const changePassword = request => async dispatch => {
    dispatch(actions.startCall());
    try {
        await accounService.changePassword(request);
        dispatch(actions.passwordChanged());
    } catch (error) {
        error.clientMessage = "ACCOUNT.PASSWORD.ACTIONS.ERROR.CHANGE_PASSWORD";
        dispatch(actions.catchError({ error }));
    }
};

export const clearSuccessMessage = () => dispatch => {
    dispatch(actions.clearSuccessMessage());
}

export const clearErrorMessage = () => dispatch => {
    dispatch(actions.clearErrorMessage());
}

import hotelTableMock from "./hotelTableMock";
import MockUtils from "../../../../redux/__mocks__/mock.utils";
import { API_SERVER_URL } from "../../../shared/constants";

const HOTELS_URL = API_SERVER_URL + "api/hotels";

export default function mockHotel(mock) {
    mock.onPost(HOTELS_URL).reply(({ data }) => {
        const hotel = JSON.parse(data);
        const {
            name = "",
            chainName = "",
            destiny = "",
            telephone = "",
            email = "",
            modules = "",
            channels = "",
            category = "",
            locationType = "",
            address = "",
            description = "",
            descriptionLarge = "",
        } = hotel;

        const id = generateHotelId();
        const newHotel = {
            id,
            name,
            chainName,
            destiny,
            telephone,
            email,
            modules,
            channels,
            category,
            locationType,
            address,
            description,
            descriptionLarge
        }
        hotelTableMock.push(newHotel);
        return [200, { hotel: newHotel }];
    });

    mock.onPut(/api\/hotels\/\d+/).reply(request => {
        const id = request.url.match(/api\/hotels\/(\d+)/)[1];
        const { hotel } = JSON.parse(request.data);
        const index = hotelTableMock.findIndex(el => el.id === +id);
        if (!index) {
            return [400];
        }

        hotelTableMock[index] = { ...hotel };
        return [200];
    });

    mock.onPost(`${HOTELS_URL}/find`).reply(request => {
        const mockUtils = new MockUtils();
        const { queryParams } = JSON.parse(request.data);
        const filteredHotels = mockUtils.baseFilter(hotelTableMock, queryParams);
        return [200, filteredHotels];
    });

    mock.onGet(/api\/hotels\/\d+/).reply(request => {
        const id = request.url.match(/api\/hotels\/(\d+)/)[1];
        const hotel = hotelTableMock.find(el => el.id === +id);
        if (!hotel) {
            return [400];
        }

        return [200, hotel];
    });
}

function generateHotelId() {
    const ids = hotelTableMock.map(el => el.id);
    const maxId = Math.max(...ids);
    return maxId + 1;
}

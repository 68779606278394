/* eslint-disable no-restricted-imports */
import React from 'react';
import clsx from 'clsx';
import { FormattedMessage } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { amber, green } from '@material-ui/core/colors';

export const MESSAGE_VARIANT = {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    INFO: 'info'
}

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const useStyles = makeStyles(theme => ({
    content: {
        marginBottom: theme.spacing(2),
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export function MessagesBar({ variant, messages, onClose }) {
    const classes = useStyles();
    const Icon = variantIcon[variant];

    return (
        <React.Fragment>
            {((messages) && (messages.length > 0)) && (
                <SnackbarContent className={clsx(classes.content, classes[variant])}
                    message={
                        <div id="client-message" className={classes.message}>
                            <div >
                                <Icon className={clsx(classes.icon, classes.iconVariant)} />
                            </div>
                            <div >
                                {messages.map((message, index) => {
                                    return (message) ? (
                                        <div key={index}>
                                            <FormattedMessage id={message} />
                                        </div>
                                    ) : '';
                                })}
                            </div>
                        </div>
                    }
                    action={[
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
                            <CloseIcon className={classes.icon} />
                        </IconButton>,
                    ]} />
            )}
        </React.Fragment>
    );
}

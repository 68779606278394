import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    currencies: [],
    currency: null,
    error: null
};

export const currencySlice = createSlice({
    name: "currency",
    initialState: initialState,
    reducers: {

        clearErrorMessage(state, action) {
            state.error = null;
        },

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
        },

        currenciesFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.currencies = action.payload.currencies;
        },

        currencyFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.currency = action.payload.currency;
        }
        
    }
});
import chainTableMock from "./chainTableMock";
import { API_SERVER_URL } from "../../../shared/constants";

const CHAINS_URL = API_SERVER_URL + "api/chains";

export default function mockChain(mock) {

    mock.onGet(CHAINS_URL).reply(() => {
        return [200, chainTableMock];
    });

}
import React from 'react';

export function SafeIframe({ title, content }) {
    const writeHTML = (frame) => {
        if (!frame) {
            return false;
        }

        const iframeDocument = frame.contentDocument;
        if (content) {
            iframeDocument.open();
            iframeDocument.write(content);
            iframeDocument.close();
        }
        frame.style.height = `${frame.contentWindow.document.body.scrollHeight}px`;
    };

    return (
        <iframe src="about:blank"
            title={title}
            width='100%'
            scrolling="no"
            frameBorder="0"
            ref={writeHTML} />
    );
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    photos: [],
    photo: null,
    error: null
};

export const meetingRoomPhotoSlice = createSlice({
    name: "meetingRoomPhoto",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.loading = true;
            state.error = null;
        },

        photosFetched: (state, action) => {
            state.loading = false;
            state.photos = action.payload.photos;
        },

        photoFetched: (state, action) => {
            state.loading = false;
            state.photo = action.payload.photo;
        },

        photoUploaded: (state, action) => {
            state.loading = false;
            state.photo = action.payload.photo;
        },

        photoDeleted: (state, action) => {
            state.loading = false;
            state.photo = null;
        },

    }
});
import { createSlice } from "@reduxjs/toolkit";
import { callTypes } from "../../../../shared/constants";

const initialState = {
    listLoading: false,
    pricesCalendar: [],
    lastError: null
};

export const pricesCalendarSlice = createSlice({
    name: "pricesCalendar",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.listLoading = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.listLoading = true;
            state.pricesCalendar = [];
        },

        pricesCalenderUploaded: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            action.payload.pricesCalendar.map((pricesCalendar) => state.pricesCalendar.push(pricesCalendar));
        },

        pricesCalenderFetched: (state, action) => {
            state.listLoading = false;
            state.error = null;
            state.pricesCalendar = action.payload.pricesCalendar;
        },
    }
});

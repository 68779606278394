/* eslint-disable no-restricted-imports */
import React, { useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/styles";
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(theme => ({
    passwordField: {
        minWidth: 120,
    }
}));

function FormPassword({ intl, name, label, value, required, error, errorMessage, onChange, onBlur }) {
    const classes = useStyles();
    const translatedLabel = intl.formatMessage({ id: label });
    const [showPassword, setShowPassword] = useState(false);

    function handleClickShowPassword() {
        setShowPassword(!showPassword);
    };

    function handleMouseDownPassword(event) {
        event.preventDefault();
    };

    return (
        <FormControl className={classes.passwordField}
            required={required}
            error={error}
            fullWidth={true}
            color="secondary"
            variant="outlined">
            <InputLabel htmlFor={name}>
                {translatedLabel}
            </InputLabel>
            <OutlinedInput name={name}
                id={name}
                label={translatedLabel}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton edge="end"
                            onClick={() => handleClickShowPassword()}
                            onMouseDown={(event) => handleMouseDownPassword(event)} >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                } />
            <FormHelperText >
                {(errorMessage) && (
                    <FormattedMessage id={errorMessage} />
                )}
            </FormHelperText>
        </FormControl>
    );
}

export default injectIntl(FormPassword);

/* eslint-disable no-restricted-imports */
import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/styles";
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles(theme => ({
    textField: {
        minWidth: 120,
    }
}));

FormTextField.defaultProps = {
    autoFocus: false,
    required:false,
    readOnly: false,
    disabled: false,
    inputComponent: 'input',
    error: false,
    multiline: false,
    rows: 1,
};

function FormTextField(
    {
        intl,
        name,
        label,
        value,
        multiline,
        rows,
        endAdornment,
        autoFocus,
        disabled,
        readOnly,
        required,
        inputComponent,
        error,
        errorMessage,
        onChange,
        onBlur
    }
) {
    const classes = useStyles();
    const translatedLabel = (label) ? intl.formatMessage({ id: label }) : "";

    return (
        <FormControl className={classes.textField}
            required={required}
            readOnly={readOnly}
            disabled={disabled}
            error={error}
            fullWidth={true}
            variant="outlined"
            color="secondary">
            <InputLabel htmlFor={name}>
                {translatedLabel}
            </InputLabel>
            <OutlinedInput id={name}
                name={name}
                multiline={multiline}
                rows={rows}
                label={translatedLabel}
                value={value}
                autoFocus={autoFocus}
                inputComponent={inputComponent}
                onChange={onChange}
                onBlur={onBlur}
                endAdornment={(endAdornment) && (
                    <InputAdornment position="end">
                        {endAdornment}
                    </InputAdornment>
                )} />
            <FormHelperText >
                {(errorMessage) && (
                    <FormattedMessage id={errorMessage} />
                )}
            </FormHelperText>
        </FormControl>
    )
}

export default injectIntl(FormTextField);

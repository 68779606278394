import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    languages: [],
    error: null
};

export const languageSlice = createSlice({
    name: "language",
    initialState: initialState,
    reducers: {

        clearErrorMessage(state, action) {
            state.error = null;
        },

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
        },

        languagesFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.languages = action.payload.languages;
        }

    }
});
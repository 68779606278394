import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    totalCount: 0,
    servicesEmailsList: [],
    servicesEmails: null,
    success: null,
    error: null
};

export const servicesEmailsSlice = createSlice({
    name: "servicesEmails",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.loading = false;
            state.error = { ...action.payload.error };
        },

        startCall: (state, action) => {
            state.loading = true;
            state.success = null;
            state.error = null;
        },

        servicesEmailsListFetched: (state, action) => {
            const { totalCount, servicesEmailsList } = action.payload;
            state.loading = false;
            state.success = null;
            state.error = null;
            state.servicesEmailsList = servicesEmailsList;
            state.totalCount = totalCount;
        },

        servicesEmailsFetched: (state, action) => {
            state.loading = false;
            state.success = null;
            state.error = null;
            state.servicesEmails = action.payload.servicesEmails;
        },

        servicesEmailsCreated: (state, action) => {
            state.loading = false;
            state.success = "SERVICES_EMAILS.MESSAGES.ACTIONS.SUCCESS.CREATE_SERVICES_EMAILS";
            state.error = null;
            state.servicesEmails = action.payload.servicesEmails;
        },

        servicesEmailsUpdated: (state, action) => {
            state.loading = false;
            state.success = "SERVICES_EMAILS.MESSAGES.ACTIONS.SUCCESS.UPDATE_SERVICES_EMAILS";
            state.error = null;
            state.servicesEmails = action.payload.servicesEmails;
        },

    }
});
/* eslint-disable no-restricted-imports */
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles({
    card: {
        width: '100%'
    },
    taxesHeader: {
        '& >span': {
            fontWeight: 'bold'
        }
    }
});

export function DetailsCard({ title, items }) {
    const classes = useStyles();

    return (
        <Card className={classes.card} >
            <CardHeader title={title} />
            <Divider />
            <CardContent>
                <List >
                    {((items) && (items.length > 0)) && (
                        items.map((item) => {
                            return (
                                <ListItem key={item.label} >
                                    <ListItemText primary={item.label} className={item.headerFont ? classes.taxesHeader : ''} />
                                    {!item.latam && <div>{item.value}</div>}
                                    {item.latam && <div style={{ width: 'calc(23% - 1px)', paddingRight: '5px', fontWeight: item.headerFont ? 'bold' : '400' }}>{item.value}</div>}
                                    {item.latam && <div style={{ width: 'calc(23% - 1px)', paddingRight: '5px', fontWeight: item.headerFont ? 'bold' : '400' }}>{item.tax}</div>}
                                    {item.latam && <div style={{ width: 'calc(23% - 1px)', paddingRight: '5px', fontWeight: item.headerFont ? 'bold' : '400' }}>{item.total}</div>}
                                </ListItem>
                            );
                        })
                    )}
                </List>
            </CardContent>
        </Card>
    );
}

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
    PersistSelectedHotel: "[PersistSelectedHotel] Action",
    ClearSelectedHotel: "[ClearSelectedHotel] Action"
}

const initialState = {
    hotel: {
        id: null,
        name: null,
        languageId: null,
        currencyId: null,
        chain: null,
        chainName: null,
        modules: null,
        channels: null,
        latam: false,
        newAdmin: false,
        disparityAcceptPrices:false,
        channelName:null,
    }
};

export const reducer = persistReducer(
    { storage, key: "byhours_saas_admin-hotel", whitelist: ["hotel"] },
    (state = initialState, action) => {
        switch (action.type) {
            case actionTypes.PersistSelectedHotel: {
                const { hotel } = action.payload;
                return { hotel };
            }

            case actionTypes.ClearSelectedHotel: {
                return initialState;
            }

            default:
                return state;
        }
    }
);

export const actions = {
    persistSelectedHotel: hotel => ({ type: actionTypes.PersistSelectedHotel, payload: { hotel } }),
    clearSelectedHotel: () => ({ type: actionTypes.ClearSelectedHotel })
};

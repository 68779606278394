import React from "react";
import { FormattedMessage } from "react-intl";

export const rowsPerPageOptions = [10, 20, 30, 40, 50];

export function getPaginationOptions(totalCount, queryParams) {
    return {
        count: totalCount,
        rowsPerPage: queryParams.pageSize,
        pageNumber: queryParams.pageNumber - 1
    };
}

export function getNewSelectedRow(entity, selectedEntity) {
    return (selectedEntity.id === entity.id) ? { id: -1} : entity;
}
export function getNewSelectedRows(event, selectedEntities) {
    if(event.target.checked) {
        selectedEntities.selectedBills.push(event.target.value);
    } else {
        removeValueCheckedFromValuesChecked(selectedEntities.selectedBills, event.target.value);
    }
    return {selectedBills: selectedEntities.selectedBills}
}

function removeValueCheckedFromValuesChecked ( selectedEntities, selectedEntitiy ) {
    var i = selectedEntities.indexOf( selectedEntitiy );
    if ( i !== -1 ) {
        selectedEntities.splice( i, 1 );
    }
}

export function getChangePage(queryParams, newPage) {
    return {
        ...queryParams,
        pageNumber: newPage + 1
    };
}

export function getChangeSize(queryParams, event) {
    const { value } = event.target;

    return {
        ...queryParams,
        pageSize: value
    };
}

export function PleaseWaitMessage({ entities }) {
    return (
        <>
            {entities === undefined && (
                <div>
                    <FormattedMessage id="GENERAL.MESSAGES.PLEASE_WAIT" />
                </div>
            )}
        </>
    );
}

export function NoRecordsFoundMessage({ entities }) {
    const entitiesList = (entities === undefined) ? [] : entities;
    return (
        <>
            {(entitiesList.length === 0) && (entities !== null) && (
                <FormattedMessage id="GENERAL.MESSAGES.NO_RECORDS_FOUND" />
            )}
        </>
    );
}

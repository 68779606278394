/* eslint-disable no-restricted-imports */
import React from 'react';
import { injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    autocompleteField: {
        minWidth: 120,
    }
}));

FormAutocomplete.defaultProps = {
    loading: false,
    multiple: false,
    showPopupIcon: 'auto',
    disabled: false,
};

function FormAutocomplete(
    {
        intl,
        name,
        label,
        value,
        textFieldValue,
        options,
        groupBy,
        loading,
        multiple,
        autoFocus,
        showPopupIcon,
        disabled,
        required,
        error,
        errorMessage,
        onChange,
        onTextFieldChange,
        onBlur,
    }
) {
    const classes = useStyles();

    return (
        <Autocomplete className={classes.autocompleteField}
            id={name}
            name={name}
            options={options}
            groupBy={groupBy}
            value={value}
            loading={loading}
            multiple={multiple}
            forcePopupIcon={showPopupIcon}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            fullWidth={true}
            disableListWrap={true}
            filterSelectedOptions={true}
            getOptionLabel={(option) => option.label}
            getOptionDisabled={(option) => option.value === ""}
            getOptionSelected={(option, value) => value.value === option.value}
            openText={intl.formatMessage({ id: "CONTROL.AUTOCOMPLETE.OPEN" })}
            closeText={intl.formatMessage({ id: "CONTROL.AUTOCOMPLETE.CLOSE" })}
            clearText={intl.formatMessage({ id: "CONTROL.AUTOCOMPLETE.CLEAR" })}
            loadingText={intl.formatMessage({ id: "CONTROL.AUTOCOMPLETE.LOADING" })}
            noOptionsText={intl.formatMessage({ id: "CONTROL.AUTOCOMPLETE.NO_OPTIONS" })}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={(label) ? intl.formatMessage({ id: label }) : ""}
                    value={textFieldValue}
                    color="secondary"
                    variant="outlined"
                    autoFocus={autoFocus}
                    required={required}
                    error={error}
                    helperText={(errorMessage) ? intl.formatMessage({ id: errorMessage }) : ""}
                    onChange={onTextFieldChange}
                    InputProps={{
                        ...params.InputProps,
                        type: 'text',
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="secondary" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            )}
        />
    );
}

export default injectIntl(FormAutocomplete);

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    totalCount: 0,
    meetingRooms: [],
    meetingRoomsOptions: [],
    meetingRoom: null,
    success: null,
    error: null,
    messages: [],
};

export const meetingRoomSlice = createSlice({
    name: "meetingRoom",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.success = null;
            state.loading = false;
        },

        startFetchMeetingRoomsCall: (state, action) => {
            state.loading = true;
            state.success = null;
            state.error = null;
            state.meetingRooms = [];
            state.totalCount = 0;
        },

        meetingRoomsFetched: (state, action) => {
            const { totalCount, meetingRooms } = action.payload;
            state.loading = false;
            state.meetingRooms = meetingRooms;
            state.totalCount = totalCount;
        },

        startFetchMeetingRoomsOptionsCall: (state, action) => {
            state.loading = true;
            state.success = null;
            state.error = null;
            state.meetingRoomsOptions = [];
        },

        meetingRoomsOptionsFetched: (state, action) => {
            state.loading = false;
            state.meetingRoomsOptions = action.payload.meetingRoomsOptions;
        },

        startCall: (state, action) => {
            state.loading = true;
            state.success = null;
            state.error = null;
        },

        meetingRoomFetched: (state, action) => {
            state.loading = false;
            state.success = null;
            state.error = null;
            state.meetingRoom = action.payload.meetingRoom;
        },

        meetingRoomCreated: (state, action) => {
            state.loading = false;
            state.success = "MEETING_ROOM_TYPE.MESSAGES.ACTIONS.SUCCESS.CREATE_MEETING_ROOM";
            state.meetingRoom = action.payload.meetingRoom;
        },

        meetingRoomUpdated: (state, action) => {
            state.loading = false;
            state.success = "MEETING_ROOM_TYPE.MESSAGES.ACTIONS.SUCCESS.UPDATE_MEETING_ROOM";
            state.meetingRoom = action.payload.meetingRoom;
        },

        meetingRoomDeleted: (state, action) => {            
            state.loading = false;
            state.success = "MEETING_ROOM_TYPE.MESSAGES.ACTIONS.SUCCESS.DELETE_MEETING_ROOM";
            state.meetingRoom = action.payload.meetingRoom;
        },

    }
});

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { MessagesBar, MESSAGE_VARIANT } from "../../../../partials";
import * as messagesBarHelpers from "../../../../_helpers/MessagesBarHelpers";
import * as actions from "../../_redux/password/passwordActions";

export function ChangePasswordMessagesBar() {
    const [messages, setMessages] = useState([]);

    const { passwordSuccess, passwordError } = useSelector(
        ({ password }) => ({
            passwordSuccess: password.success,
            passwordError: password.error,
        }),
        shallowEqual
    );

    useEffect(() => {
        setMessages(messagesBarHelpers.buildMessage(messages, passwordSuccess, MESSAGE_VARIANT.SUCCESS));
    }, [passwordSuccess]);

    useEffect(() => {
        if (passwordError) {
            setMessages(messagesBarHelpers.buildMessage(messages, passwordError.clientMessage, MESSAGE_VARIANT.ERROR));
        }
    }, [passwordError]);

    const dispatch = useDispatch();
    function onChangePasswordMessageClose(message) {
        const newMessages = messages.filter(arrMessage => (arrMessage !== message));
        setMessages(newMessages);
        if ((passwordError) && (message.messages.includes(passwordError.clientMessage))) {
            dispatch(actions.clearErrorMessage());
        }
        if ((passwordSuccess) && (message.messages.includes(passwordSuccess))) {
            dispatch(actions.clearSuccessMessage());
        }
    }

    return (
        <React.Fragment>
            {(messages) && (
                messages.map((message, index) => {
                    return (
                        <MessagesBar key={index}
                            variant={message.variant}
                            messages={message.messages}
                            onClose={() => onChangePasswordMessageClose(message)} />
                    );
                })
            )}
        </React.Fragment>
    );
}

import React, { Component } from "react";
import { Formik, Field } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Input } from "../../../../_metronic/_partials/controls";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { resetPassword } from "../_redux/authCrud";

class ResetPassword extends Component {

    state = { isRequested: false };
    state = { class: 'alert alert-danger' };
    render() {
        const { intl } = this.props;
        const { match: { params } } = this.props;
        const code = params.code;
        const id = params.id;
        const getInputClasses = (meta, fieldname) => {
            let result = "form-control form-control-solid h-auto py-5 px-6 ";
            if (meta.touched && meta.error) {
                result += " is-invalid"
            }

            if (meta.touched && !meta.error) {
                result += " is-valid"
            }

            return result;
        }

        return (
            <div className="login-form">
                <div className="text-center mb-10 mb-lg-20">
                    <h3 className="font-size-h1">
                        <FormattedMessage id="ACCOUNT.PASSWORD.CHANGE_PASSWORD.PAGE.TITLE" />
                    </h3>
                </div>

                <Formik
                    initialValues={{ newPassword: "", passwordConfirm: "", }}
                    validate={values => {
                        const errors = {};
                        if (values.newPassword == '' ) {
                            errors.newPassword = intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" });
                        }
                        if (values.passwordConfirm == '' ) {
                            errors.passwordConfirm = intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" });
                        }
                        if(values.newPassword != '' && values.passwordConfirm != '') {
                            if (values.newPassword != values.passwordConfirm) {
                                errors.passwordConfirm = intl.formatMessage({ id: "ACCOUNT.PASSWORD.CHANGE_PASSWORD.ERROR.MESSAGE.NEW_AND_CONFIRM_PASSWORD_NOT_MATCH" });
                            }
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        resetPassword(id, code, values.newPassword)
                            .then((response) => {
                                let message = "";
                                if (response.status === 200) {
                                    message = "ACCOUNT.PASSWORD.ACTIONS.SUCCESS.CHANGE_PASSWORD";
                                }
                                setStatus(intl.formatMessage({ id: message }));
                                this.setState({ isRequested: true });
                                this.setState({ class: 'alert alert-success' });
                                setSubmitting(false);
                            })
                            .catch(({response}) => {
                                let message = "";
                                if (response !== undefined) {
                                    if (response.status === 500) {
                                        message = "ACCOUNT.PASSWORD.CHANGE_PASSWORD.ERROR.PASSWORD";
                                    }
                                } else {
                                    message = "AUTH.VALIDATION.NETWORK_ERROR"
                                }
                                setSubmitting(false);
                                this.setState({ class: 'alert alert-danger' });
                                setStatus(intl.formatMessage({ id: message }));
                            });
                    }} >
                    {({
                          values,
                          status,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                      }) => (
                        <form onSubmit={handleSubmit} className="form">
                            {status && (
                                <div role="alert" className={this.state.class}>
                                    <div className="alert-text">{status}</div>
                                </div>
                            )}
                            <div className="form-group">
                                <Field name="newPassword"
                                       component={Input}
                                       placeholder="Password"
                                       label="Password" >
                                    {({ field, form, meta }) => (
                                        <div>
                                            <input type="password"
                                                   {...field}
                                                   className={`${getInputClasses(meta)}`}
                                                   placeholder={intl.formatMessage({ id: "ACCOUNT.PASSWORD.CHANGE_PASSWORD.FIELD.NEW_PASSWORD" })} />
                                            {meta.touched && meta.error && (
                                                <div className="error invalid-feedback">
                                                    {meta.error}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <div className="form-group">
                                <Field name="passwordConfirm"
                                       component={Input}
                                       placeholder="Password"
                                       label="Password" >
                                    {({ field, form, meta }) => (
                                        <div>
                                            <input type="password"
                                                   {...field}
                                                   className={`${getInputClasses(meta)}`}
                                                   placeholder={intl.formatMessage({ id: "ACCOUNT.PASSWORD.CHANGE_PASSWORD.FIELD.CONFIRM_PASSWORD" })} />
                                            {meta.touched && meta.error && (
                                                <div className="error invalid-feedback">
                                                    {meta.error}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <div className="form-group d-flex flex-wrap flex-center">
                                <button id="kt_login_forgot_submit"
                                        type="submit"
                                        className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                        disabled={isSubmitting} >
                                    <FormattedMessage id="AUTH.GENERAL.SUBMIT.BUTTON" />
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));

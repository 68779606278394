/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { userHasRole } from "../../../../../app/_helpers";
import { ROLES } from "../../../../../app/shared/constants";
import PeopleIcon from '@material-ui/icons/People';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
export function HeaderMenu({ layoutProps }) {
    const { user } = useSelector(state => state.auth);
    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }

    return <div id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes} >

        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>

            {(userHasRole(user.roles, ROLES.SUPER_ADMIN) || userHasRole(user.roles, ROLES.COMPANY_MANAGER)) && (
                <>
                    {/* Hotels */}
                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/hotel/list')}`}>
                        <NavLink className="menu-link" to="/hotel/list">
                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Hotel.svg")} />
                            </span>
                            <span className="menu-text">
                                <FormattedMessage id="MENU.HOTELS" />
                            </span>
                        </NavLink>
                    </li>
                </>)}

            {(userHasRole(user.roles, ROLES.SUPER_ADMIN) || userHasRole(user.roles, ROLES.COMPANY_MANAGER)) && (
                <>
                    {/* Users */}
                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/user/list')}`}>
                        <NavLink className="menu-link" to="/user/list">
                            <span className="svg-icon menu-icon">
                                <PeopleIcon />
                            </span>
                            <span className="menu-text">
                                <FormattedMessage id="MENU.USERS" />
                            </span>
                        </NavLink>
                    </li>
                </>
            )}
            {(userHasRole(user.roles, ROLES.SUPER_ADMIN)) && (
                <>

                    {/* EMAILSENDERS */}
                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/mailsSenders/list')}`}>
                        <NavLink className="menu-link" to="/mailsSenders/list">
                            <span className="svg-icon menu-icon">
                                <MailOutlineIcon />
                            </span>
                            <span className="menu-text">
                                <FormattedMessage id="MENU.EMAIL.SENDERS" />
                            </span>
                        </NavLink>
                    </li>
                </>
            )}
            {(userHasRole(user.roles, ROLES.SUPER_ADMIN)) && (
                <>

                    {/* Invoices */}
                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/bill/list')}`}>
                        <NavLink className="menu-link" to="/bill/list">
                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File-done.svg")} />
                            </span>
                            <span className="menu-text">
                                <FormattedMessage id="MENU.INVOICES" />
                            </span>
                        </NavLink>
                    </li>
                </>
            )}

            {(userHasRole(user.roles, ROLES.SUPER_ADMIN) || userHasRole(user.roles, ROLES.COMPANY_MANAGER)) && (
                <>
                    {/* Logout */}
                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/logout')}`}>
                        <NavLink className="menu-link" to="/logout">
                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Door-open.svg")} />
                            </span>
                            <span className="menu-text">
                                <FormattedMessage id="AUTH.LOGOUT.BUTTON" />
                            </span>
                        </NavLink>
                    </li>
                </>
            )}

        </ul>
        {/*end::Header Nav*/}

    </div>;
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    totalCount: 0,
    hotels: [],
    hotelsOptions: [],
    hotel: null,
    success: null,
    error: null
};

export const hotelSlice = createSlice({
    name: "hotel",
    initialState: initialState,
    reducers: {

        restoreInitialHotelState: (state, action) => {
            state = initialState;
        },

        clearSuccessMessage(state, action) {
            state.success = null;
        },

        clearErrorMessage(state, action) {
            state.error = null;
        },

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.success = null;
            state.loading = false;
        },

        startCall: (state, action) => {
            state.loading = true;
            state.success = null;
            state.error = null;
        },

        // getHotelById
        hotelFetched: (state, action) => {
            state.loading = false;
            state.success = null;
            state.error = null;
            state.hotel = action.payload.hotel;
        },

        startFetchHotelsCall: (state, action) => {
            state.loading = true;
            state.success = null;
            state.error = null;
            state.hotels = [];
            state.totalCount = 0;
        },

        // findHotels
        hotelsFetched: (state, action) => {
            const { totalCount, hotels } = action.payload;
            state.loading = false;
            state.hotels = hotels;
            state.totalCount = totalCount;
        },

        hotelsOptionsFetched: (state, action) => {
            state.loading = false;
            state.success = null;
            state.error = null;
            state.hotelsOptions = action.payload.hotelsOptions;
        },

        // createHotel
        hotelCreated: (state, action) => {
            state.loading = false;
            state.success = "HOTEL.MESSAGES.ACTIONS.SUCCESS.CREATE_HOTEL";
            state.hotel = action.payload.hotel;
        },

        // updateHotel
        hotelUpdated: (state, action) => {
            state.loading = false;
            state.success = "HOTEL.MESSAGES.ACTIONS.SUCCESS.UPDATE_HOTEL";
            state.hotel = action.payload.hotel;
        },

        configurationUpdated: (state, action) => {
            state.loading = false;
            state.error = null;
            state.success = "HOTEL.MESSAGES.ACTIONS.SUCCESS.UPDATE_CONFIGURATION";
            state.hotel = action.payload.hotel;
        },

        // getHotelById
        hotelFetchedValidated: (state, action) => {
            state.loading = false;
            state.hotel = action.payload.hotel;
            state.error = null;
            state.success = "HOTEL.MESSAGES.ACTIONS.SUCCESS.VALIDATED_HOTEL";
        },        
    }
});  

import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import { userSlice } from "../app/modules/User/_redux/user/userSlice";
import { passwordSlice } from "../app/modules/Account/_redux/password/passwordSlice";
import * as hotelPersist from "../app/modules/Hotel/_redux/hotel/hotelPersistReducer";
import { hotelSlice } from "../app/modules/Hotel/_redux/hotel/hotelSlice";
import { billingDataSlice } from "../app/modules/Hotel/_redux/billing-data/billingDataSlice";
import { chainSlice } from "../app/modules/Hotel/_redux/chain/chainSlice";
import { starSlice } from "../app/modules/Hotel/_redux/star/starSlice";
import { serviceSlice } from "../app/modules/Hotel/_redux/services/servicesSlice";
import { availabilitySlice } from "../app/modules/Hotel/_redux/availability/availabilitySlice";
import { avaDayViewSlice } from "../app/modules/Hotel/_redux/availability/avaDayViewSlice";
import { updateScrappingPricesSlice } from "../app/modules/Hotel/_redux/update-scrapping-prices/updateScrappingPricesSlice";
import { meetingRoomAvaSlice } from "../app/modules/Hotel/_redux/mr-availability/availabilitySlice";
import { meetingRoomBulkUploadSlice } from "../app/modules/Hotel/_redux/mr-availability/bulkUploadSlice";
import { mrBlockSlice } from "../app/modules/Hotel/_redux/mr-blocks/mrBlockSlice";
import { destinationSlice } from "../app/modules/Hotel/_redux/destination/destinationSlice";
import { channelSlice } from "../app/modules/Hotel/_redux/channel/channelSlice";
import { roomTypeClassificationSlice } from "../app/modules/Hotel/_redux/roomTypeClassification/roomTypeClassificationSlice";
import { roomTypeSlice } from "../app/modules/Hotel/_redux/roomType/roomTypeSlice";
import { promotionSlice } from "../app/modules/Hotel/_redux/promotion/promotionSlice";
import { myDataSlice } from "../app/modules/Hotel/_redux/myData/myDataSlice";
import { photoSlice } from "../app/modules/Hotel/_redux/photo/photoSlice";
import { servicesEmailsSlice } from "../app/modules/Hotel/_redux/services-emails/servicesEmailsSlice";
import { languageSlice } from "../app/modules/Hotel/_redux/language/languageSlice";
import { currencySlice } from "../app/modules/Hotel/_redux/currency/currencySlice";
import { moduleSlice } from "../app/modules/Hotel/_redux/module/moduleSlice";
import { statisticsSlice } from "../app/modules/Hotel/_redux/statistics/statisticsSlice";
import { mrDashboardSlice } from "../app/modules/Hotel/_redux/mr-dashboard/mrDashboardSlice";
import { roomBookingSlice } from "../app/modules/Hotel/_redux/room-booking/roomBookingSlice";
import { roomBookingConfigSlice } from "../app/modules/Hotel/_redux/room-booking-config/roomBookingConfigSlice";
import { billSlice } from "../app/modules/Bill/_redux/bill/billSlice";
import { billPaySlice } from "../app/modules/Bill/_redux/billPay/billPaySlice";
import { billsPreviousCardSlice } from "../app/modules/Bill/_redux/billsPreviousCard/billsPreviousCardSlice";
import { billsSelectedSlice } from "../app/modules/Bill/_redux/billsSelected/billsSelectedSlice";
import { mailsSendersSlice } from "../app/modules/MailsSenders/_redux/mailsSenders/mailsSendersSlice";
import { roomAvailabilitySlice } from "../app/modules/Hotel/_redux/room-availability/roomAvailabilitySlice";
import { pricesCalendarSlice } from "../app/modules/Hotel/_redux/prices-calendar/pricesCalendarSlice";
import { pricesDisparitySlice } from "../app/modules/Hotel/_redux/prices-disparity/pricesDisparitySlice";
import { billCodePromoSlice } from "../app/modules/Hotel/_redux/billCodePromo/billCodePromoSlice";

import { disparitySlice } from "../app/modules/Hotel/_redux/disparity/disparitySlice";
import { hotelServicesSlice } from "../app/modules/Hotel/_redux/hotel-services/hotelServicesSlice";
import { emailTemplateSlice } from "../app/modules/Hotel/_redux/email-template/emailTemplateSlice";
import { servicesHotelSlice } from "../app/modules/Hotel/_redux/services-hotel/servicesHotelSlice";
import { hotelExtrasSlice } from "../app/modules/Hotel/_redux/hotel-extras/hotelExtrasSlice";
import { hotelContactPersonsSlice } from "../app/modules/Hotel/_redux/hotel-contact-persons/hotelContactPersonsSlice";
import { meetingRoomSlice } from "../app/modules/Hotel/_redux/meeting-room/meetingRoomSlice";
import { meetingRoomServiceSlice } from "../app/modules/Hotel/_redux/meeting-room-service/meetingRoomServiceSlice";
import { meetingRoomDispositionSlice } from "../app/modules/Hotel/_redux/meeting-room-disposition/meetingRoomDispositionSlice";
import { meetingRoomPackSlice } from "../app/modules/Hotel/_redux/meeting-roon-pack/meetingRoomPackSlice";
import { meetingRoomAmenitySlice } from "../app/modules/Hotel/_redux/meeting-room-amenity/meetingRoomAmenitySlice";
import { meetingRoomPhotoSlice } from "../app/modules/Hotel/_redux/meeting-room-photo/meetingRoomPhotoSlice";
import { meetingRoomBookSlice } from "../app/modules/Hotel/_redux/meeting-room-book/meetingRoomBookSlice";
import { configSlice } from "../app/modules/Config/_redux/configSlice";
import { widgetServicesSlice } from "../app/modules/Hotel/_redux/room-widget/roomWidgetServicesSlice";
import { configTemplateSlice } from "../app/modules/Config/_redux/configTemplateSlice";
import { roomTaxesSlice } from "../app/modules/Hotel/_redux/room-taxes/roomTaxesSlice";
import { meetingRoomTaxesSlice } from "../app/modules/Hotel/_redux/meeting-room-taxes/meetingRoomTaxesSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  user: userSlice.reducer,
  password: passwordSlice.reducer,
  hotelPersist: hotelPersist.reducer,
  hotel: hotelSlice.reducer,
  billingData: billingDataSlice.reducer,
  chain: chainSlice.reducer,
  destination: destinationSlice.reducer,
  star: starSlice.reducer,
  channel: channelSlice.reducer,
  roomTypeClassification: roomTypeClassificationSlice.reducer,
  roomType: roomTypeSlice.reducer,
  promotion: promotionSlice.reducer,
  myData: myDataSlice.reducer,
  photo: photoSlice.reducer,
  servicesEmails: servicesEmailsSlice.reducer,
  language: languageSlice.reducer,
  currency: currencySlice.reducer,
  module: moduleSlice.reducer,
  hotelExtras: hotelExtrasSlice.reducer,
  roomAvailability: roomAvailabilitySlice.reducer,
  pricesCalendar: pricesCalendarSlice.reducer,
  pricesDisparity: pricesDisparitySlice.reducer,
  billCodePromo:billCodePromoSlice.reducer,
  disparity: disparitySlice.reducer,
  roomBooking: roomBookingSlice.reducer,
  roomBookingConfig: roomBookingConfigSlice.reducer,
  bill: billSlice.reducer,
  billPay: billPaySlice.reducer,
  billsPreviousCard: billsPreviousCardSlice.reducer,
  billsSelected: billsSelectedSlice.reducer,
  mailsSenders: mailsSendersSlice.reducer,
  hotelServices: hotelServicesSlice.reducer,
  servicesHotel: servicesHotelSlice.reducer,
  hotelContactPersons: hotelContactPersonsSlice.reducer,
  meetingRoom: meetingRoomSlice.reducer,
  meetingRoomAmenity: meetingRoomAmenitySlice.reducer,
  meetingRoomDisposition: meetingRoomDispositionSlice.reducer,
  meetingRoomService: meetingRoomServiceSlice.reducer,
  meetingRoomPack: meetingRoomPackSlice.reducer,
  meetingRoomPhoto: meetingRoomPhotoSlice.reducer,
  meetingRoomBook: meetingRoomBookSlice.reducer,
  frontConfig: configSlice.reducer,
  monthStatistics: statisticsSlice.reducer,
  mrDashboardSlice: mrDashboardSlice.reducer,
  configTemplates: configTemplateSlice.reducer,
  availability: availabilitySlice.reducer,
  dayView: avaDayViewSlice.reducer,
  updateScrappingPrices: updateScrappingPricesSlice.reducer,
  service: serviceSlice.reducer,
  widgetServices: widgetServicesSlice.reducer,
  emailTemplate: emailTemplateSlice.reducer,
  meetingRoomAvailability: meetingRoomAvaSlice.reducer,
  meetingRoomBulkUpload: meetingRoomBulkUploadSlice.reducer,
  meetingRoomBlocks: mrBlockSlice.reducer,
  roomTaxes: roomTaxesSlice.reducer,
  meetingRoomTaxes: meetingRoomTaxesSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}

import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { ChangePasswordPage } from "./modules/Account/pages/change-password/ChangePasswordPage";

const HotelRoute = lazy(() =>
    import("./modules/Hotel/HotelRoute")
);

const BillRoute = lazy(() =>
    import("./modules/Bill/BillRoute")
);

const UserRoute = lazy(() =>
    import("./modules/User/UserRoute")
);
const MailsSendersRoute = lazy(() =>
    import("./modules/MailsSenders/MailsSendersRoute")
);
export default function BasePage() {

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <ContentRoute path="/change-password" component={ChangePasswordPage} />
                <Route path="/hotel" component={HotelRoute} />
                <Route path="/bill" component={BillRoute} />
                <Route path="/user" component={UserRoute} />
                <Route path="/mailsSenders" component={MailsSendersRoute} />
                <Redirect exact from="/" to="/hotel" />
                <Redirect to="error" />
            </Switch>
        </Suspense>
    );
}

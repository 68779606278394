/* eslint-disable no-restricted-imports */
import React from "react";
import { injectIntl } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
    formControl: {
        width: 200,
    },
    circularProgress: {
        marginRight: theme.spacing(2),
    }
}));

function SearchSelect(
    {
        intl,
        name,
        label,
        options,
        value,
        loading,
        onChange,
        onBlur,
    }
) {
    const classes = useStyles();
    const translatedLabel = (label) ? intl.formatMessage({ id: label }) : "";

    return (
        <FormControl className={classes.formControl}
            variant="outlined"
            color="secondary" >
            <InputLabel id={name}>
                {translatedLabel}
            </InputLabel>
            <Select labelId={name}
                id={name}
                name={name}
                label={translatedLabel}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                endAdornment={loading
                    ? <CircularProgress className={classes.circularProgress} color="secondary" size={26} />
                    : null} >
                {options.map((option) => {
                    return (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

export default injectIntl(SearchSelect);

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    error: null,
    previousCard: null,
};

export const billsPreviousCardSlice = createSlice({
    name: "billsPreviousCard",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.loading = false;
            state.error = { ...action.payload.error };
        },

        startCall: (state, action) => {
            state.loading = true;
            state.error = null;
            state.previousCard = null;
        },

        billsPreviousCardFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.previousCard = action.payload;
        }
    }
});

import {
    createSlice
} from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    totalCount: 0,
    books: [],
    book: null,
    success: null,
    error: null,
    canCancel: false,
    canCancelMessage: null,
};

export const meetingRoomBookSlice = createSlice({
    name: "meetingRoomBook",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = {
                ...action.payload.error
            };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.success = null;
            state.loading = true;
            state.canCancel = false;
            state.canCancelMessage = null;
        },


        bookFetched: (state, action) => {
            state.loading = false;
            state.book = action.payload.book;
        },

        searchReferencia: (state, action) => {
            state.loading = false;
            state.books = action.payload
            state.totalCount = action.payload.length
        },

        startFetchBooksCall: (state, action) => {
            state.error = null;
            state.success = null;
            state.canCancelMessage = null;
            state.loading = true;
            state.books = [];
            state.totalCount = 0;
        },

        booksFetched: (state, action) => {
            const {
                totalCount,
                books
            } = action.payload;
            state.loading = false;
            state.books = books;
            state.totalCount = totalCount;
        },

        bookCancelled: (state, action) => {
            state.loading = false;
            state.canCancel = true;
            state.canCancelMessage = 'BOOKING.MESSAGES.ACTIONS.SUCCESS.CANCEL_BOOKING_CANCACELLED';
            state.success = "MEETING_ROOM.BOOK.MESSAGES.ACTIONS.SUCCESS.CANCEL_BOOK";
        },
    }
});
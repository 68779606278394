import { createSlice } from "@reduxjs/toolkit";
import { callTypes } from "../../../../shared/constants";

const initialState = {
    loading: false,
    roomTaxes: null,
    error: null,
    latam: false,
};

export const roomTaxesSlice = createSlice({
    name: "roomTaxes",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.loading = true;
            state.error = null;
        },

        resetState: (state, action) => {
            state.loading = false;
            state.error = null;
            state.roomTaxes = null;
            state.latam = false;
        },

        roomTaxesFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.roomTaxes = action.payload.taxes;
            state.latam = action.payload.latam;
        },

        roomTaxesUpdated: (state, action) => {
            state.loading = false;
            state.error = null;
            state.roomTaxes = action.payload.taxes;
        }
    }
});

/* eslint-disable no-restricted-imports */
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { FormSwitchGroup } from "../controls/forms/FormSwitchGroup";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
}));

export function SwitchPanel({ label, options, xs, sm, md, lg, xl, onChange }) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent>

                <FormSwitchGroup label={label}
                    options={options}
                    xs={xs} sm={sm} md={md} lg={lg} xl={xl}
                    onChange={onChange} />

            </CardContent>
        </Card>
    );
}

import React from "react";
import { FormattedMessage } from "react-intl";
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';

export function ColorPalette(
    {
        field,
        label,
        handleChangePrimary,
        handleClosePrimary,
        displayColorPickerPrimary,
        handleClickPrimary,
        textColorPrimary,
        colorPrimary,
        otherInputStyle,
        error
    }
) {
    const styles = reactCSS({
        'default': {
            colorPrimary: {
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                background: `rgba(${colorPrimary.r}, ${colorPrimary.g}, ${colorPrimary.b}, ${colorPrimary.a})`,
            },
            swatch: {
                borderRadius: '1px',
                display: 'inline-block',
                cursor: 'pointer',
                marginLeft: '-25px',
                position: 'absolute',
                paddingTop: '6px',
            },
            popover: {
                position: 'absolute',
                zIndex: '50',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
            label: {
                height: '35px'
            },
            error: {
                fontSize: '0.9rem',
                fontWeight: '400',
                color: '#F64E60'
            }
        },
    });

    return (
        <div>
            {(label) && (
                <div>
                    <label>
                        <FormattedMessage id={label} />
                    </label>
                </div>
            )}
            <div>
                <input readOnly
                    type="text"
                    {...field}
                    value={textColorPrimary}
                    style={Object.assign(styles.label, otherInputStyle || {})} />
                <div style={styles.swatch} onClick={handleClickPrimary}>
                    <div style={styles.colorPrimary} />
                </div>
                {(displayColorPickerPrimary) && (
                    <div style={styles.popover}>
                        <div style={styles.cover} onClick={handleClosePrimary} />
                        <SketchPicker color={colorPrimary} onChange={handleChangePrimary} />
                    </div>
                )}
                {(error) && (
                    <div className="invalid-input" style={styles.error} >
                        {error}
                    </div>
                )}
            </div>
        </div>
    );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    totalCount: 0,
    roomTypes: [],
    roomType: null,
    success: null,
    error: null
};

export const roomTypeSlice = createSlice({
    name: "roomType",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.success = null;
            state.loading = false;
        },

        startFetchRoomTypesCall: (state) => {
            state.loading = true;
            state.totalCount = 0;
            state.roomTypes = [];
            state.success = null;
            state.error = null;
        },

        // findRoomTypes
        roomTypesFetched: (state, action) => {
            const { totalCount, roomTypes } = action.payload;
            state.loading = false;
            state.roomTypes = roomTypes;
            state.totalCount = totalCount;
        },

        startCall: (state) => {
            state.loading = true;
            state.success = null;
            state.error = null;
        },

        // getRoomTypeById
        roomTypeFetched: (state, action) => {
            state.loading = false;
            state.roomType = action.payload.roomType;
        },

        // createRoomType
        roomTypeCreated: (state, action) => {
            state.loading = false;
            state.success = "ROOM_TYPE.MESSAGES.ACTIONS.SUCCESS.CREATE_ROOM_TYPE";
            state.roomType = action.payload.roomType;
        },

        // updateRoomType
        roomTypeUpdated: (state, action) => {
            state.loading = false;
            state.success = "ROOM_TYPE.MESSAGES.ACTIONS.SUCCESS.UPDATE_ROOM_TYPE";
            state.roomType = action.payload.roomType;
        },

        roomTypeDeleted: (state, action) => {
            state.loading = false;
            state.success = "ROOM_TYPE.MESSAGES.ACTIONS.SUCCESS.DELETE_ROOM_TYPE";
            state.roomType = action.payload.roomType;
        },

    }
});  

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    channels: [],
    error: null
};

export const channelSlice = createSlice({
    name: "channel",
    initialState: initialState,
    reducers: {
        
        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
        },

        channelsFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.channels = action.payload.channels;
        },

    }
});

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    stars: [],
    star: null,
    error: null
};

export const starSlice = createSlice({
    name: "star",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.loading = true;
            state.error = null;
        },

        starsFetched: (state, action) => {
            state.loading = false;
            state.stars = action.payload.stars;
        },

        starFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.star = action.payload.star;
        }

    }
});

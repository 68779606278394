import {
    createSlice
} from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    totalCount: 0,
    bookings: [],
    booking: null,
    error: null,
    bookCancel: false,
    canCancelMessage: null,
    noShowPayment: null,
    bookPaymentStatus: null,
    cancelWithFee: null,
    cancelError:null,
};

export const roomBookingSlice = createSlice({
    name: "roomBooking",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = {
                ...action.payload.error
            };
            state.loading = false;
        },

        startFetchBookinsCall: (state, action) => {
            state.loading = true;
            state.canCancelMessage = null;
            state.error = null;
            state.bookings = [];
            state.totalCount = 0;
            state.bookPaymentStatus = null;
            state.noShowPayment = null;
        },

        bookingsFetched: (state, action) => {
            const {
                totalCount,
                bookings
            } = action.payload;
            state.loading = false;
            state.bookings = bookings;
            state.totalCount = totalCount;
        },
        
        searchBookinksForReferenc: (state,action) => {
            state.loading = false;
            state.bookings = action.payload;
            state.totalCount = action.payload.length ;
        },

        startCall: (state, action) => {
            state.loading = true;
            state.canCancelMessage = null;
            state.bookCancel = false;
            state.error = null;
            state.bookPaymentStatus = null;
            state.noShowPayment = null;
            state.cancelWithFee = null;
            state.cancelError = null;
        },
        bookingFetched: (state, action) => {
            state.loading = false;
            state.booking = action.payload.booking;
        },

        bookingCancelled: (state, action) => {
            state.bookCancel = true;
            state.canCancelMessage = 'BOOKING.MESSAGES.ACTIONS.SUCCESS.CANCEL_BOOKING';
            state.loading = false;
        },

        startCreateNoShowPaymentCall: (state, action) => {
            state.error = null;
            state.loading = true;
            state.noShowPayment = null;
            state.canCancelMessage = null;
            state.cancelWithFee = null;
            state.cancelError = null;
        },

        startCancelWithFeePaymentCall: (state, action) => {
            state.error = null;
            state.loading = true;
            state.cancelWithFee = null;
            state.cancelError = null;
        },

        noShowPaymentCreated: (state, action) => {
            state.loading = false;
            state.noShowPayment = action.payload.noShowPayment;
        },

        cancelWithFee: (state, action) => {
            state.loading = false;
            state.cancelWithFee = action.payload.cancelWithFee;
            state.cancelError = null;
        },
        cancelWithFeeError: (state, action) => {
            state.cancelError = true;
            state.loading = false;
            state.cancelWithFee = action.payload.cancelWithFee;
        },
        startBookPaymentStatusCall: (state, action) => {
            state.error = null;
            state.loading = true;
            state.bookPaymentStatus = null;
            state.cancelWithFee = null;
            state.cancelError = null;
        },

        bookPaymentStatus: (state, action) => {
            state.loading = false;
            state.bookPaymentStatus = action.payload;
        },

        resetState: (state) => {
            state.loading = false;
            state.bookPaymentStatus = null;
            state.noShowPayment = null;
            state.error = null;
            state.bookCancel = false;
            state.canCancelMessage = null;
        },
    }
});
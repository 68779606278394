import React, { useMemo } from "react";
import { useHtmlClassService } from "../../_core/MetronicLayout";

export function Footer() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true)
    };
  }, [uiService]);

  return (
    <div className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer" >
      <div className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`} >
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted font-weight-bold mr-2">2020</span> &copy;{" "}
          <a href="https://www.byhours.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-dark-75 text-hover-primary" >
            BYHOURS
          </a>
        </div>
      </div>
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import { MESSAGE_TYPES } from "../../../../shared/constants";

const initialState = {
    isLoading: false,
    successfulUpload: null,
    successfulClose: null,
    success: null,
    messages: [],
};

const { SUCCESS, ERROR } = MESSAGE_TYPES;

export const meetingRoomBulkUploadSlice = createSlice({
    name: "MeetingRoomBulkUpload",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            const { clientMessage } = action.payload;
            state.isLoading = false;
            state.successfulUpload = null;
            state.successfulClose = null;
            state.messages.push({
                messages: [clientMessage], type: ERROR
            });
        },

        startCall: (state, action) => {
            state.isLoading = true;
            state.successfulUpload = null;
            state.successfulClose = null;
        },

        massUploadSuccessfull: (state, action) => {
            state.isLoading = false;
            state.successfulUpload = true;
            state.messages.push({ messages: [action.payload.message], type: action.payload.errorType ? ERROR : SUCCESS });
        },

        massCloseSuccessfull: (state, action) => {
            state.isLoading = false;
            state.successfulClose = true;
            state.messages.push({ messages: [action.payload.message], type: SUCCESS });
        },

        removeMessage: (state) => {
            state.messages = [];
        },

        clearState: (state) => {
            state.isLoading = false;
            state.successfulUpload = null;
            state.successfulClose = null;
            state.success = null;
            state.messages = [];
        }
    }
});

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    totalCount: 0,
    mailsSenders: [],
    sent: false,
    remove: false,
    error: null,
    success: null,
};

export const mailsSendersSlice = createSlice({
    name: "mailsSenders",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.loading = false;
            state.error = { ...action.payload.error };
        },

        startCall: (state, action) => {
            state.loading = true;
            state.error = null;
            state.success = null;
            state.mailsSenders = [];
            state.totalCount = 0;
        },

        mailsSendersFetched: (state, action) => {
            const { mailsSenders, totalCount } = action.payload;
            state.loading = false;
            state.mailsSenders = mailsSenders;
            state.totalCount = totalCount;
        },

        resendMail: (state, action) => {
            const { sent } = action.payload;
            state.loading = false;
            state.sent = sent;
            state.success = "MAIL_LIST.MESSAGES.ACTIONS.SUCCESS.RESEND_MAIL";
        },

        deleteMail: (state, action) => {
            const { remove } = action.payload;
            state.loading = false;
            state.success = "MAIL_LIST.MESSAGES.ACTIONS.SUCCESS.DELETE_MAIL";
            state.delete = remove;
        }
        

    }
});

import axios from "axios";
import { API_SERVER_URL } from "../../../../shared/constants";
import MockAdapter from "axios-mock-adapter";
import mockHotel from "../../__mocks__/mockHotel";
import mockChain from "../../__mocks__/mockChain";
import mockDestiny from "../../__mocks__/mockDestiny";

const HOTELS_URL = API_SERVER_URL + "api/v1/by_hotels";

export function mockAxios() {
    const mock = new MockAdapter(axios, { delayResponse: 300 });

    mockHotel(mock);
    mockChain(mock);
    mockDestiny(mock);
}

export function createHotel(hotel) {
    return axios.post(HOTELS_URL, hotel);
}

export function updateHotel(hotel) {
    return axios.put(`${HOTELS_URL}/${hotel.id}`, hotel);
}

export function getAllHotels() {
    return axios.get(HOTELS_URL);
}

export function getHotelById(hotelId) {
    return axios.get(`${HOTELS_URL}/${hotelId}`);
}

export function validatedHotel(id) {
    return axios.put(`${HOTELS_URL}/${id}/validate`, { "validated": true });
}

export function findHotels(queryParams) {
    const { locale, pageNumber, pageSize, filter } = queryParams;
    const { name, chain, destination, channels } = filter;
    const GET_HOTELS_URL = `${HOTELS_URL}/by_locale/${locale}?page=${pageNumber}&itemsPerPage=${pageSize}`;
    const nameParam = (name) ? `&name=${name}` : "";
    const chainParam = (chain) ? `&chain=${chain}` : "";
    const destinationParam = (destination) ? `&destination.id=${destination}` : "";
    let channelsParam = "";
    if (channels) {
        channels.forEach(channel => {
            channelsParam = channelsParam + `&channels%5B%5D=${channel}`
        });
    }

    const REQUEST_URL = GET_HOTELS_URL + nameParam + chainParam + destinationParam + channelsParam;
    return axios.get(REQUEST_URL);
}

export function updateConfiguration(request) {
    const { hotelId, configuration } = request;
    return axios.put(`${HOTELS_URL}/${hotelId}/configuration`, configuration);
}

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  refreshToken: "Rrefres TOKEN"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  refresh_token: undefined,
};

export const reducer = persistReducer(
  { storage, key: "byhours_saas_admin-auth", whitelist: ["user", "authToken", "refresh_token"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, refresh_token } = action.payload;

        return { authToken, refresh_token, user: undefined };
      }
      case actionTypes.refreshToken: {
        const { authToken, refresh_token } = action.payload;
        return { ...state, authToken, refresh_token };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return {
          user: undefined,
          authToken: undefined,
          refresh_token: undefined
        };
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken, refresh_token) => ({ type: actionTypes.Login, payload: { authToken, refresh_token } }),
  refreshToken: (authToken, refresh_token) => ({ type: actionTypes.refreshToken, payload: { authToken, refresh_token } }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: body } = yield getUserByToken();
    const user = {
      id: body.id,
      userName: body.username,
      email: body.email,
      roles: body.roles,
      chainId: body.chainId,
      chain: body.chain,
      hotels: body.hotels
    }

    yield put(actions.fulfillUser(user));
  });
}

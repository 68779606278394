/* eslint-disable no-restricted-imports */
import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
}));

export function ListContainer({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {children}
    </div>
  )
}

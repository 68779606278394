import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    error: null,
    errorInternalServer:null,
    frontConfig: null,
    frontConfigSaved: false,
};

export const configSlice = createSlice({
    name: "frontConfig",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error =  { ...action.payload.error } 
            state.loading = false;
            state.errorInternalServer = null;
            state.frontConfigSaved = false;
        },

        catchInternalServer: (state, action) => {
            const { clientMessage } = action.payload;
            state.errorInternalServer = clientMessage;
            state.loading = false;
            state.error = null;
            state.frontConfigSaved = false;
        },

        startCall: (state, action) => {
            state.loading = true;
            state.error = null;
            state.errorInternalServer = null;
            state.frontConfigSaved = false;
            state.errorInternalServer = null;
        },

        // load 
        frontConfigFetch: (state, action) => {
            const { frontConfig, totalCount } = action.payload;
            state.loading = false;
            state.error = null;
            state.errorInternalServer = null;
            state.frontConfig = frontConfig;
            state.frontConfigItems = totalCount;
        },

        // updateFrontConfig
        frontConfigUpdated: (state, action) => {
            state.error = null;
            state.errorInternalServer = null;
            state.loading = false;
            state.frontConfigSaved = true;
            state.frontConfig = action.payload.frontConfig;
        },

        frontConfigCreated: (state, action) => {
            const { frontConfig } = action.payload;
            state.frontConfig = frontConfig;
            state.error = null;
            state.errorInternalServer = null;
            state.loading = false;
            state.frontConfigSaved = true;
        },
    }

});

import { createSlice } from "@reduxjs/toolkit";
import { callTypes } from "../../../../shared/constants";

const initialState = {
    loading: false,
    meetingRoomTaxes: null,
    error: null,
    latam: false,
};

export const meetingRoomTaxesSlice = createSlice({
    name: "meetingRoomTaxes",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.loading = true;
            state.error = null;
        },

        resetState: (state, action) => {
            state.loading = false;
            state.error = null;
            state.meetingRoomTaxes = null;
            state.latam = false;
        },

        meetingRoomTaxesFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.meetingRoomTaxes = action.payload.taxes;
            state.latam = action.payload.latam;
        },

        meetingRoomTaxesUpdated: (state, action) => {
            state.loading = false;
            state.error = null;
            state.meetingRoomTaxes = action.payload.taxes;
        }

    }
});

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    totalCount: 0,
    users: [],
    user: null,
    success: null,
    error: null
};

export const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        
        catchError: (state, action) => {
            state.loading = false;
            state.error = { ...action.payload.error };
        },

        startCall: (state, action) => {
            state.loading = true;
            state.success = null;
            state.error = null;
        },

        // getUserById
        userFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.success = null;
            state.user = action.payload.user;
        },

        // findUsers
        usersFetched: (state, action) => {
            const { users, totalCount} = action.payload;
            state.loading = false;
            state.error = null;
            state.users = users;
            state.totalCount = totalCount;
        },

        // createUser
        userCreated: (state, action) => {
            state.loading = false;
            state.error = null;
            state.success = "USER.MESSAGES.ACTIONS.SUCCESS.CREATE_USER";
            state.user = action.payload.user;
        },

        // updateUser
        userUpdated: (state, action) => {
            state.loading = false;
            state.error = null;
            state.success = "USER.MESSAGES.ACTIONS.SUCCESS.UPDATE_USER";
            state.user = action.payload.user;
        },

        userPasswordChanged: (state, action) => {
            state.loading = false;
            state.error = null;
            state.success = "USER.MESSAGES.ACTIONS.SUCCESS.CHANGE_PASSWORD";
            state.user = action.payload.user;
        }
        
    }
});  

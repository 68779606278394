/* eslint-disable no-restricted-imports */
import React from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
    paper: {
        width: '100%',
        overflowX: 'auto',
        marginBottom: theme.spacing(2),
    },
    expansionPanelHeading: {
        fontWeight: "bold",
        fontSize: theme.typography.pxToRem(16),
        flexBasis: '33.33%',
        flexShrink: 0,
        paddingLeft: theme.spacing(1),
    },
}));

BaseExpansionPanel.defaultProps = {
    expanded: false
};

export function BaseExpansionPanel({ icon, title, children, expanded }) {
    const classes = useStyles();
    const [expansionPanelExpanded, setExpansionPanelExpanded] = React.useState(expanded);
    const handleExpansionPanelChange = () => {
        setExpansionPanelExpanded(!expansionPanelExpanded);
    };

    return (
        <Paper className={classes.paper}>
            <ExpansionPanel expanded={expansionPanelExpanded}
                onChange={handleExpansionPanelChange}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}
                    aria-controls="expansion-panel-content"
                    id="expansion-panel-header">
                    {icon}
                    <Typography className={classes.expansionPanelHeading}>
                        <FormattedMessage id={title} />
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {children}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </Paper>
    );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    hotelWidgetConfiguration: {
        id: '',
        logo: '',
        logoUrl: '',
        backgroundImage: '',
        backgroundImageUrl: '',
        title: '',
        subTitle: '',
        width: '300',
        height: '500',
        fontSize: '12px',
        fontFamily: 'Lucida, sans-serif',
        orientation: 'vertical',
        primaryColor: '#FA8213',
        secondaryColor: '#000000'
    },
    hotelBTNWidgetConfiguration: {
        id: '',
        title: '',
        fontSize: '12px',
        fontFamily: 'Lucida, sans-serif',
        primaryColor: '#FA8213',
        secondaryColor: '#000000'
    },
    needUpdate: false,
    lastError: null,
    success: null,
};

export const widgetServicesSlice = createSlice({
    name: "hotelRoomWidgetServices",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
            state.needUpdate = false;
        },

        startCall: (state, action) => {
            state.success = null;
            state.error = null;
            state.loading = true;
            state.needUpdate = false;
        },

        hotelRoomWidgetServicesFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.hotelWidgetConfiguration = action.payload.roomWidgetConfiguration ? action.payload.roomWidgetConfiguration : initialState.hotelWidgetConfiguration;
        },

        hotelBTNWidgetServicesFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.hotelBTNWidgetConfiguration = action.payload.roomWidgetConfiguration ? action.payload.roomWidgetConfiguration : initialState.hotelBTNWidgetConfiguration;
        },

        hotelRoomWidgetServiceStatusUpdated: (state, action) => {
            state.loading = false;
            state.error = "WIDGET.FORM.ERROR.SAVE_WIDGET";
            state.needUpdate = true;
            state.success = "WIDGET.FORM.UPDATE_MESSAGE";
        },

        hotelRoomWidgetServiceStatusCreated: (state, action) => {
            state.loading = false;
            state.error = "WIDGET.FORM.ERROR.SAVE_WIDGET";
            state.needUpdate = true;
            state.success = "WIDGET.FORM.SUCCESS_MESSAGE";

        }

    }
});

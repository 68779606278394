import { createSlice } from "@reduxjs/toolkit";

import { MESSAGE_TYPES } from "../../../../shared/constants";
const initialState = {
    isLoading: false,
    availabilityRecords: [],
    availableDates: [],
    dataUpdated: false,
    success: null,
    error: null,
    messages: [],
};
const { SUCCESS, ERROR } = MESSAGE_TYPES;

export const meetingRoomAvaSlice = createSlice({
    name: "MeetingRoomAvailability",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            const { clientMessage } = action.payload;
            state.isLoading = false;
            state.dataUpdated = false;
            state.messages.push({
                messages: [clientMessage], type: ERROR
            });
        },

        startCall: (state, action) => {
            state.error = null;
            state.isLoading = true;
            state.dataUpdated = false;
        },
        
        recordsFetched: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.availabilityRecords = action.payload.availability;
        },

        dataUpdated: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.dataUpdated = true;
        },

        availbleDaysUpdated: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.availableDates = action.payload.availableDates;
        },

        removeMessage: (state) => {
            state.success = null;
            state.error = null;
        },

        clearState: (state) => {
            state = initialState;
        }
    }
});

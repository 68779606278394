import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    totalCount: 0,
    bills: [],
    error: null
};

export const billSlice = createSlice({
    name: "bill",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.loading = false;
            state.error = { ...action.payload.error };
        },

        startCall: (state, action) => {
            state.loading = true;
            state.error = null;
            state.bills = [];
            state.totalCount = 0;
        },

        billsFetched: (state, action) => {
            const { bills, totalCount } = action.payload;
            state.loading = false;
            state.bills = bills;
            state.totalCount = totalCount;
        }

    }
});

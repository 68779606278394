/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {useEffect} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Layout} from "../_metronic/layout";
import BasePage from "./BasePage";
import {Logout, AuthPage} from "./modules/Auth";
import {ErrorPage} from "./pages/ErrorPage";
import * as hotelPersist from "./modules/Hotel/_redux/hotel/hotelPersistReducer";
import * as hotelActions from "./modules/Hotel/_redux/hotel/hotelActions";
import { useLang } from "../_metronic/i18n";
export function Routes() {
    const {isAuthorized} = useSelector(
        ({auth}) => ({
            isAuthorized: auth.user != null,
        }),
        shallowEqual
    );
    const { hotel } = useSelector(state => state.hotelPersist);
    const locale = useLang();
    const dispatch = useDispatch();
    useEffect(() => {
        const hotelId = hotel?hotel.id:null;
        if (hotelId)
        dispatch(hotelActions.fetchHotel(hotelId));
    }, [])
    return (
        <Switch>
            {!isAuthorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage/>
                </Route>
            ) : (
                /*Otherwise redirect to root page (`/`)*/
                <Redirect from="/auth" to="/"/>
            )}

            <Route path="/error" component={ErrorPage}/>
            <Route path="/logout" component={Logout}/>


            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth/login"/>
            ) : (
                <Layout>
                    <BasePage/>
                </Layout>
            )}
        </Switch>
    );
}

/* eslint-disable no-restricted-imports */
import React from "react";
import { injectIntl } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    autocomplete: {
        minWidth: 150,
    },
}));

SearchAutocomplete.defaultProps = {
    loading: false,
    multiple: false,
    showPopupIcon: 'auto',
    disableClearable: false,
};

function SearchAutocomplete(
    {
        intl,
        name,
        label,
        options,
        value,
        loading,
        showPopupIcon,
        disableClearable,
        multiple,
        onChange,
        onInputChange,
        onBlur
    }
) {
    const classes = useStyles();

    return (
        <Autocomplete className={classes.autocomplete}
            id={name}
            name={name}
            options={options}
            value={value}
            loading={loading}
            multiple={multiple}
            forcePopupIcon={showPopupIcon}
            disableClearable={disableClearable}
            onChange={onChange}
            onInputChange={onInputChange}
            onBlur={onBlur}
            fullWidth={true}
            disableListWrap={true}
            filterSelectedOptions={true}
            getOptionLabel={(option) => option.label}
            getOptionDisabled={(option) => option.value === ""}
            getOptionSelected={(option, value) => value.value === option.value}
            openText={intl.formatMessage({ id: "CONTROL.AUTOCOMPLETE.OPEN" })}
            closeText={intl.formatMessage({ id: "CONTROL.AUTOCOMPLETE.CLOSE" })}
            clearText={intl.formatMessage({ id: "CONTROL.AUTOCOMPLETE.CLEAR" })}
            loadingText={intl.formatMessage({ id: "CONTROL.AUTOCOMPLETE.LOADING" })}
            noOptionsText={intl.formatMessage({ id: "CONTROL.AUTOCOMPLETE.NO_OPTIONS" })}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label?intl.formatMessage({ id: label }):undefined}
                    color="secondary"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        type: 'text',
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="secondary" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

export default injectIntl(SearchAutocomplete);

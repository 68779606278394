/* eslint-disable no-restricted-imports */
import React from "react";
import {Formik, Form} from 'formik';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/HighlightOff';
import {BaseExpansionPanel} from "./BaseExpansionPanel";
import {SearchButton} from "../controls/buttons/SearchButton";
import {buttonType} from "../../_helpers/FormHelpers";

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%',
    },
}));

export function SearchPanel(
    Filters,
    {
        initialValues,
        handleSubmitForm,
        handleClearForm,
        expanded
    }
) {
    const classes = useStyles();

    return (
        <BaseExpansionPanel icon={<SearchIcon/>} title="SEARCH_PANEL.TITLE" expanded={expanded}>
            <Formik initialValues={initialValues}
                    validateOnChange={true}
                    validateOnBlur={true}
                    onSubmit={(values) => handleSubmitForm(values)}>
                {(props) => {
                    const {
                        resetForm
                    } = props;

                    return (
                        <Form className={classes.form}>
                            <Grid container justify="center" spacing={2}>
                                <Filters {...props} />
                                <Grid container justify="center" spacing={2} item xs={12}>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                </Grid>
                                <Grid container justify="flex-end" spacing={2} item xs={12}>
                                    <Grid item xs={6} sm={4} md={3} lg={2}>
                                        <SearchButton
                                            icon={<ClearIcon/>}
                                            label="SEARCH_PANEL.BUTTONS.CLEAR"
                                            onClick={() => handleClearForm({resetForm})}/>
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={3} lg={2}>
                                        <SearchButton
                                            icon={<SearchIcon/>}
                                            label="SEARCH_PANEL.BUTTONS.SEARCH"
                                            type={buttonType.SUBMIT}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </BaseExpansionPanel>
    );
}

/* eslint-disable no-restricted-imports */
import React from 'react';
import { injectIntl, FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/styles";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    circularProgress: {
        marginRight: theme.spacing(2),
    }
}));

function FormSelect(
    {
        intl,
        name,
        label,
        options,
        value,
        loading,
        required,
        disabled,
        error,
        errorMessage,
        onChange,
        onBlur,
        translateOptionText
    }
) {
    const classes = useStyles();
    const translatedLabel = (label) ? intl.formatMessage({ id: label }) : "";

    return (
        <FormControl className={classes.formControl}
            required={required}
            disabled={disabled}
            error={error}
            fullWidth={true}
            variant="outlined"
            color="secondary" >
            <InputLabel id={name}>
                {translatedLabel}
            </InputLabel>
            <Select labelId={name}
                id={name}
                name={name}
                label={translatedLabel}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                endAdornment={loading ? <CircularProgress className={classes.circularProgress} color="secondary" size={26} /> : null} >
                {options.map((option) => {
                    return (
                        <MenuItem key={option.value} value={option.value}>
                            {
                                translateOptionText ?
                                    intl.formatMessage({ id: option.label }) : option.label
                            }
                        </MenuItem>
                    );
                })}
            </Select>
            <FormHelperText >
                {(errorMessage) && (
                    <FormattedMessage id={errorMessage} />
                )}
            </FormHelperText>
        </FormControl>
    );
}

export default injectIntl(FormSelect);

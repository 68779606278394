/* eslint-disable no-restricted-imports */
import React from 'react';
import { FormattedMessage } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    paper: {
        width: 300,
        height: 400,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

export function FormTransferList(
    {
        leftLabel,
        rightLabel,
        leftOptions,
        rightOptions,
        selectedListItems,
        disabledMoveAllItemsRightButton,
        disabledMoveSelectedItemsRightButton,
        disabledMoveSelectedItemsLeftButton,
        disabledMoveAllItemsLeftButton,
        onListItemClick,
        onMoveAllItemsRightClick,
        onMoveSelectedItemsRightClick,
        onMoveSelectedItemsLeftClick,
        onMoveAllItemsLeftClick
    }
) {
    const classes = useStyles();

    const customList = (label, items) => (
        <>
            <FormLabel>
                <FormattedMessage id={label} />
            </FormLabel>
            <Paper className={classes.paper} variant="outlined" >
                <List dense component="div" role="list">
                    {((items) && (items.length > 0)) && (items.map((item) => {
                        const labelId = `transfer-list-item-${item.value}-label`;

                        return (
                            <ListItem key={item.value} role="listitem" button onClick={() => onListItemClick(item)}>
                                <ListItemIcon>
                                    <Checkbox
                                        checked={selectedListItems.indexOf(item) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }} />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={item.label} />
                            </ListItem>
                        );
                    }))}
                </List>
            </Paper>
        </>
    );

    return (
        <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
            <Grid item>{customList(leftLabel, leftOptions)}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={() => onMoveAllItemsRightClick()}
                        disabled={disabledMoveAllItemsRightButton}
                        aria-label="move all right" >
                        ≫
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={() => onMoveSelectedItemsRightClick()}
                        disabled={disabledMoveSelectedItemsRightButton}
                        aria-label="move selected right" >
                        &gt;
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={() => onMoveSelectedItemsLeftClick()}
                        disabled={disabledMoveSelectedItemsLeftButton}
                        aria-label="move selected left" >
                        &lt;
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={() => onMoveAllItemsLeftClick()}
                        disabled={disabledMoveAllItemsLeftButton}
                        aria-label="move all left" >
                        ≪
                    </Button>
                </Grid>
            </Grid>
            <Grid item>{customList(rightLabel, rightOptions)}</Grid>
        </Grid>
    );
}

/* eslint-disable no-restricted-imports */
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import SubmitIcon from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/HighlightOff';
import { FormButton } from "../controls/buttons/FormButton";
import { buttonType } from "../../_helpers/FormHelpers";

const useStyles = makeStyles(theme => ({
    paper: {
        width: '100%',
        overflowX: 'auto',
    },
    form: {
        marginTop: theme.spacing(1),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
    },
}));

export function FormPanel(FormFields, props) {
    const {
        initialValues,
        validationSchema,
        handleSubmitForm,
        handleCancelForm,
    } = props;
    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <Formik initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange={true}
                validateOnBlur={true}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting }) => handleSubmitForm(values, { setSubmitting })} >
                {(props) => {
                    const {
                        dirty,
                        isValid,
                        isSubmitting,
                        resetForm,
                    } = props;

                    return (
                        <Form className={classes.form}>
                            <FormFields {...props} />
                            <div className={classes.divider}>
                                <Divider />
                            </div>
                            <Toolbar>
                                <Grid container justify="flex-end" spacing={2}>

                                    <Grid item xs={6} sm={4} md={3} lg={2}>
                                        <FormButton icon={<ClearIcon />}
                                            label="GENERAL.ACTIONS.CANCEL"
                                            disabled={false}
                                            onClick={() => handleCancelForm({ resetForm })} />
                                    </Grid>

                                    <Grid item xs={6} sm={4} md={3} lg={2}>
                                        <FormButton icon={<SubmitIcon />}
                                            label="GENERAL.ACTIONS.SAVE"
                                            type={buttonType.SUBMIT}
                                            disabled={!dirty || !isValid || isSubmitting} />
                                    </Grid>

                                </Grid>
                            </Toolbar>
                        </Form>
                    );
                }}
            </Formik>
        </Paper>
    );
}

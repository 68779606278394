/* eslint-disable no-restricted-imports */
import React from "react";
import { injectIntl } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from "date-fns/locale/en-US";
import esLocale from "date-fns/locale/es";
import itLocale from "date-fns/locale/it";
import frLocale from "date-fns/locale/fr";
import ptLocale from "date-fns/locale/pt";
import deLocale from "date-fns/locale/de";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

const useStyles = makeStyles(theme => ({
    datePicker: {
        minWidth: 150,
    },
}));

function SearchDatePicker({ intl, name, label, value, minDate, maxDate, locale, format, onChange, onBlur }) {
    const classes = useStyles();
    const localeMap = {
        en: enLocale,
        es: esLocale,
        it: itLocale,
        pt: ptLocale,
        fr: frLocale,
        de: deLocale,
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
            <KeyboardDatePicker className={classes.datePicker}
                name={name}
                label={(label) ? intl.formatMessage({ id: label }) : ""}
                value={value}
                minDate={minDate}
                maxDate={maxDate}
                format={format}
                autoOk={true}
                fullWidth={true}
                variant="inline"
                inputVariant="outlined"
                color="secondary"
                invalidDateMessage={intl.formatMessage({ id: "CONTROL.DATEPICKER.INVALID_DATE_MESSAGE" })}
                minDateMessage={intl.formatMessage({ id: "CONTROL.DATEPICKER.MIN_DATE_MESSAGE" })}
                maxDateMessage={intl.formatMessage({ id: "CONTROL.DATEPICKER.MAX_DATE_MESSAGE" })}
                onChange={onChange}
                onBlur={onBlur} />
        </MuiPickersUtilsProvider>
    );
}

export default injectIntl(SearchDatePicker);

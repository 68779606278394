import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    blockedDates: [],
    success: null,
    error: null
};

export const mrBlockSlice = createSlice({
    name: "MeetingRoomBlockings",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.isLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.isLoading = true;
        },
        blockedDatesFetched: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.blockedDates = action.payload.blockedDates;
        },
    }
});

import { createSlice } from "@reduxjs/toolkit";
import { MESSAGE_TYPES } from "../../../../shared/constants";

const initialState = {
    isLoading: false,
    successfulUpdate: false,
    pricesValidationChecked: false,
    messages: [],
};
const { SUCCESS, ERROR } = MESSAGE_TYPES;

export const availabilitySlice = createSlice({
    name: "Availability",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            const { clientMessage, additionalMessage } = action.payload;
            const messages = [clientMessage];
            if (additionalMessage) {
                messages.push(additionalMessage)
            }
            state.messages.push({
                messages: messages, type: ERROR
            });
            state.isLoading = false;
            state.successfulUpdate = false
        },
        startCall: (state) => {
            state.isLoading = true;
            state.successfulUpdate = false;
            state.pricesValidationChecked = false;
        },
        recordUpdated: (state, action) => {
            state.isLoading = false;
            state.successfulUpdate = true;
            state.messages.push({ messages: [action.payload.message], type: SUCCESS });
            state.pricesValidationChecked = false;
        },
        pricesValidationchecked: (state, action) => {
            state.isLoading = false;
            state.pricesValidationChecked = true;
        },
        removeMessages: (state) => {
            state.messages = [];
        },
        clearState: (state) => {
            state.messages = [];
            state.successfulUpdate = false;
            state.isLoading = false;
            state.pricesValidationChecked = false;
        }
    }
});

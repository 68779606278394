import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    services: [],
    service: null,
    error: null
};

export const meetingRoomServiceSlice = createSlice({
    name: "meetingRoomService",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
        },

        servicesFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.services = action.payload.services;
        },

        serviceFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.service = action.payload.service;
        },

    }
});

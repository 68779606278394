import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    totalCount: 0,
    emailTemplates: [],
    emailTemplate: null,
    success: null,
    error: null
};

export const emailTemplateSlice = createSlice({
    name: "emailTemplate",
    initialState: initialState,
    reducers: {

        restoreInitialEmailTemplateState: (state, action) => {
            state = initialState;
        },

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startFetchEmailTemplatesCall: (state, action) => {            
            state.loading = true;
            state.success = null;
            state.error = null;
            state.emailTemplates = [];
            state.totalCount = 0;
        },

        // findEmailTemplates
        emailTemplatesFetched: (state, action) => {
            const { totalCount, emailTemplates } = action.payload;
            state.loading = false;
            state.emailTemplates = emailTemplates;
            state.totalCount = totalCount;
        },

        startCall: (state, action) => {
            state.success = null;
            state.error = null;
            state.loading = true;
        },

        // getEmailTemplateById
        emailTemplateFetched: (state, action) => {
            state.loading = false;
            state.emailTemplate = action.payload.emailTemplate;
        },

        // createEmailTemplate
        emailTemplateCreated: (state, action) => {
            state.loading = false;
            state.success = "EMAIL_TEMPLATE.MESSAGES.ACTIONS.SUCCESS.CREATE_EMAIL_TEMPLATE";
        },

        // updateEmailTemplate
        emailTemplateUpdated: (state, action) => {
            state.loading = false;
            state.success = "EMAIL_TEMPLATE.MESSAGES.ACTIONS.SUCCESS.UPDATE_EMAIL_TEMPLATE";
        },

        emailTemplateDeleted: (state, action) => {
            state.loading = false;
            state.success = "EMAIL_TEMPLATE.MESSAGES.ACTIONS.SUCCESS.DELETE_EMAIL_TEMPLATE";
        }
        
    }
});  

/* eslint-disable no-restricted-imports */
import React from "react";
import Grid from '@material-ui/core/Grid';
import { FormPassword } from "../../../../partials";

export function ChangePasswordFormFields(
    {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
    }
) {
    return (
        <Grid container justify="flex-start" spacing={2} item xs={6}>
            <Grid item xs={12}>
                <FormPassword name="currentPassword"
                    required={true}
                    label="ACCOUNT.PASSWORD.CHANGE_PASSWORD.FIELD.CURRENT_PASSWORD"
                    value={values.currentPassword}
                    error={errors.currentPassword && touched.currentPassword}
                    errorMessage={(errors.currentPassword && touched.currentPassword) && errors.currentPassword}
                    onChange={handleChange}
                    onBlur={handleBlur} />
            </Grid>
            <Grid item xs={12}>
                <FormPassword name="newPassword"
                    required={true}
                    label="ACCOUNT.PASSWORD.CHANGE_PASSWORD.FIELD.NEW_PASSWORD"
                    value={values.newPassword}
                    error={errors.newPassword && touched.newPassword}
                    errorMessage={(errors.newPassword && touched.newPassword) && errors.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur} />
            </Grid>
            <Grid item xs={12}>
                <FormPassword name="confirmPassword"
                    required={true}
                    label="ACCOUNT.PASSWORD.CHANGE_PASSWORD.FIELD.CONFIRM_PASSWORD"
                    value={values.confirmPassword}
                    error={errors.confirmPassword && touched.confirmPassword}
                    errorMessage={(errors.confirmPassword && touched.confirmPassword) && errors.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur} />
            </Grid>
        </Grid>
    );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    data: null,
    error: null,
    dataPay:null,
    success:null
};

export const billPaySlice = createSlice({
    name: "billPay",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.loading = false;
            state.success = null;
            state.error = { ...action.payload.error };
        },

        startCall: (state, action) => {
            state.loading = true;
            state.error = null;
            state.data = null;
            state.success = null;
        },

        billPayIntentFetched: (state, action) => {
            state.loading = true;
            state.error = null;
            state.data =  action.payload.payment_intent_id;
        },

        billPayFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.dataPay =  action.payload.data;
            state.success = "BILL_LIST.ACTIONS.BILLS_PAYED_SUCCESS";
        },

        clearMessage(state, action) {
            state.success = null;
            state.error= null;
        },

    }
});

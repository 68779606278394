/* eslint-disable no-restricted-imports */
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
    input: {
        display: 'none',
    },
    label: {
        marginTop: theme.spacing(1),
    },
    actionButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        minWidth: 130,
    }
}));

export function UploadActionButton({ icon, label, disabled, id, accept, multiple, onChange }) {
    const classes = useStyles();

    return (
        <>
            <input accept={accept}
                className={classes.input}
                id={id}
                multiple={multiple}
                type="file"
                onChange={(event) => onChange(event)} />
            <label htmlFor={id} className={classes.label}>
                <Button className={classes.actionButton}
                    variant="contained"
                    color="secondary"
                    component="span"
                    startIcon={icon}
                    disabled={disabled}>
                    <FormattedMessage id={label} />
                </Button>
            </label>
        </>
    );
}

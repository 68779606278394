/* eslint-disable no-restricted-imports */
import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Radio from '@material-ui/core/Radio';
import {NoRecordsFoundMessage, rowsPerPageOptions} from "../../_helpers";
import TableSortLabel from '@material-ui/core/TableSortLabel';

const useStyles = makeStyles(theme => ({
    table: {
        width: '100%',
        overflowX: 'auto',
        minWidth: 700,
    },
}));

const StyledTableCell = withStyles(theme => ({
    head: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: "bold",
    },
    body: {
        fontSize: theme.typography.pxToRem(14),
    },
}))(TableCell);

function EnhancedTableHead({tableHead, useCheckBox,onSort,orderBy}) {
    const createSortHandler = property => event => {
        let type = "desc"
        if (property === orderBy.field ){
            type = orderBy.type==="desc"?'asc':"desc";
        }
        onSort(property,type);
    };
    return (
        <TableHead>
            <TableRow>
                {
                    (useCheckBox &&
                        <StyledTableCell></StyledTableCell>
                    )}
                {(tableHead && (tableHead.length > 0)) && (
                    tableHead.map(cell => (
                        <StyledTableCell key={cell.id} align={'center'} padding={'default'}>

                            {cell.sort && onSort?
                                <TableSortLabel
                                    active={cell.id === orderBy.field}
                                    direction={cell.id === orderBy.field?orderBy.type:"desc"}
                                    onClick={createSortHandler(cell.id)}
                                >
                                    <FormattedMessage id={cell.label}/>
                                </TableSortLabel> :
                                <FormattedMessage id={cell.label}/>}
                        </StyledTableCell>
                    ))
                )}
            </TableRow>
        </TableHead>
    );
}

function SelectionRow(
    {
        tableHead,
        entity,
        selectedEntity,
        onChangeSelectedRow,
        useCheckBox,
        useButton,
        onClickSelectedButton
    }
) {
    const isEntitySelected = (entity.id === selectedEntity.id);

    return (
        <TableRow hover={true}
                  onClick={() => onChangeSelectedRow(entity)}
                  aria-checked={isEntitySelected}
                  tabIndex={-1}
                  selected={isEntitySelected}>
            {
                (useCheckBox &&
                    <TableCell padding="checkbox">
                        <Radio name="row-radio"
                               value={entity.id}
                               checked={isEntitySelected}
                               onChange={() => onChangeSelectedRow(entity)}/>
                    </TableCell>
                )}
            {(tableHead && (tableHead.length > 0)) && (
                tableHead.map((cell, columnIndex) => (
                        ((entity[cell.id] != null && entity[cell.id].hasOwnProperty('show')) ?
                                (useButton && entity[cell.id].show ?
                                        <TableCell align={cell.dataAlignment}>
                                            <ButtonStyledTable
                                                className={entity[cell.id].className}
                                                classNameIcon={entity[cell.id].classNameIcon}
                                                value={entity.id}
                                                entity={entity}
                                                onClickButton={() => onClickSelectedButton(entity, entity[cell.id].type)}
                                                formattedMessage={entity[cell.id].formattedMessage}
                                            />
                                        </TableCell>
                                        :
                                        <TableCell/>
                                )
                                :
                                <StyledTableCell align={cell.dataAlignment}
                                                 key={entity.id.toString() + columnIndex.toString()}>
                                    {entity[cell.id]}
                                </StyledTableCell>
                        )
                    )
                )
            )
            }

        </TableRow>
    );
}

function ButtonStyledTable(
    {
        className,
        value,
        onClickButton,
        entity,
        formattedMessage,
        classNameIcon,
    }) {
    return (
        <button type="button"
                className={className}
                value={value}
                onClick={() => onClickButton(entity)}>
            {(formattedMessage ?
                    <FormattedMessage id={formattedMessage}/>
                    :
                    <i className={classNameIcon}></i>
            )}
        </button>
    )
}

function SingleRowSelectionTable(
    {
        intl,
        tableHead,
        entities,
        selectedEntity,
        paginationOptions,
        onChangeSelectedRow,
        onClickSelectedButton,
        onChangePage,
        onChangeRowsPerPage,
        onSort,
        orderBy,
        useCheckBox = true,
        useButton = false,
    }) {
    const classes = useStyles();
    const columnCount = (tableHead && (tableHead.length > 0)) ? (tableHead.length + 1) : 0;

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="medium">
                <EnhancedTableHead tableHead={tableHead} useCheckBox={useCheckBox} onSort={onSort} orderBy={orderBy}/>
                <TableBody>
                    {(entities && (entities.length > 0)) && (
                        entities.map((entity) => (
                            <SelectionRow key={entity.id}
                                          tableHead={tableHead}
                                          entity={entity}
                                          selectedEntity={selectedEntity}
                                          onChangeSelectedRow={onChangeSelectedRow}
                                          onClickSelectedButton={onClickSelectedButton}
                                          useCheckBox={useCheckBox}
                                          useButton={useButton}
                            />
                        ))
                    )}

                    {(entities && (entities.length === 0)) && (
                        <TableRow style={{height: 100}}>
                            <TableCell style={{margin: 2}} colSpan={columnCount}>
                                <NoRecordsFoundMessage entities={entities}/>
                            </TableCell>
                        </TableRow>
                    )}

                </TableBody>
            </Table>
            <TablePagination component="div"
                             rowsPerPageOptions={rowsPerPageOptions}
                             count={paginationOptions.count}
                             rowsPerPage={paginationOptions.rowsPerPage}
                             page={paginationOptions.pageNumber}
                             labelRowsPerPage={intl.formatMessage({id: "TABLE.PAGINATOR.ROWS_PER_PAGE"})}
                             labelDisplayedRows={(items) => intl.formatMessage(
                                 {id: "TABLE.PAGINATOR.DISPLAYED_ROWS"},
                                 {from: items.from, to: items.to, count: items.count},
                             )}
                             backIconButtonText={intl.formatMessage({id: "TABLE.PAGINATOR.PREVIOUS_PAGE"})}
                             nextIconButtonText={intl.formatMessage({id: "TABLE.PAGINATOR.NEXT_PAGE"})}
                             onChangePage={onChangePage}
                             onChangeRowsPerPage={onChangeRowsPerPage}/>
        </TableContainer>
    );
}

export default injectIntl(SingleRowSelectionTable);

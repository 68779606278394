import { createSlice } from "@reduxjs/toolkit";
import { callTypes } from "../../../../shared/constants";

const initialState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    pricesDisparity: [],
    lastError: null
};

export const pricesDisparitySlice = createSlice({
    name: "pricesDisparity",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.listLoading = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.listLoading = true;
            state.pricesDisparity = [];
        },
        pricesDisparityFetched: (state, action) => {
            state.listLoading = false;
            state.error = null;
            state.pricesDisparity = action.payload.pricesDisparity;
            state.totalCount = action.payload.totalCount;
        },
    }
});

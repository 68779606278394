import React from "react";
import { PageContainer } from "../../../../partials";
import { ChangePasswordActionsBar } from "./ChangePasswordActionsBar";
import { ChangePasswordMessagesBar } from "./ChangePasswordMessagesBar";
import { ChangePasswordFormPanel } from "./ChangePasswordFormPanel";

export function ChangePasswordLayout() {

    return (
        <PageContainer>
            <ChangePasswordActionsBar />
            <ChangePasswordMessagesBar />
            <ChangePasswordFormPanel />
        </PageContainer>
    )
}

import { createSlice } from "@reduxjs/toolkit";
import { callTypes } from "../../../../shared/constants";

const initialState = {
    listLoading: false,
    actionsLoading: false,
    hotelExtras: [],
    hotelExtra: undefined,
    lastError: null
};

export const hotelExtrasSlice = createSlice({
    name: "hotelExtras",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },

        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },

        resetState: (state) => {
            state.listLoading = false;
            state.actionsLoading = false;
            state.hotelExtras = [];
            state.hotelExtra = null;
            state.lastError = null;
            state.successfulUpdate = false;
        },

        hotelExtrasFetched: (state, action) => {
            state.listLoading = false;
            state.error = null;
            state.hotelExtras = action.payload.hotelExtras;
        },

        hotelExtraStatusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.hotelExtras = state.hotelExtras.map(stHotelExtra => {
                if (stHotelExtra.id === action.payload.hotelExtra.id) {
                    return action.payload.hotelExtra;
                }
                return stHotelExtra;
            });
        }

    }
});

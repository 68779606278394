/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import {ADD_ANALYTICS} from "../app/shared/constants";

export default function App({ store, persistor, basename }) {

  if (ADD_ANALYTICS === 'true') {
    var tag1 = document.createElement('script');
    tag1.async = true;
    tag1.src = "https://www.googletagmanager.com/gtag/js?id=UA-29403265-23";
    var tag2 = document.createElement('script');

    tag2.innerHTML = 'window.dataLayer = window.dataLayer || [];\n' +
      '      function gtag() {\n' +
      '      dataLayer.push(arguments)\n' +
      '    }\n' +
      '      gtag(\'js\', new Date());\n' +
      '      gtag(\'config\', \'UA-29403265-23\');'
    document.getElementsByTagName('head')[0].appendChild(tag1);
    document.getElementsByTagName('head')[0].appendChild(tag2);
  }
  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <Routes />
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}

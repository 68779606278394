/* eslint-disable no-restricted-imports */
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import SubmitIcon from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/HighlightOff';
import { FormButton } from "../controls/buttons/FormButton";
import { buttonType } from "../../_helpers/FormHelpers";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    form: {
        marginTop: theme.spacing(1),
    }
}));

export function GridFormPanel(FormFields, props) {
    const {
        formValues,
        validationSchema,
        handleSubmitForm,
        handleCancelForm,
    } = props;
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent>
                <Formik initialValues={formValues}
                    validationSchema={validationSchema}
                    validateOnChange={true}
                    validateOnBlur={true}
                    enableReinitialize={true}
                    onSubmit={(values, { setSubmitting }) => handleSubmitForm(values, { setSubmitting })} >
                    {(props) => {
                        const {
                            dirty,
                            isValid,
                            isSubmitting,
                            resetForm,
                        } = props;

                        return (
                            <Form className={classes.form}>
                                <Grid container justify="center" spacing={2}>
                                    <FormFields {...props} />
                                    <Grid container justify="flex-end" spacing={2} item xs={12}>

                                        <Grid item xs={12} >
                                            <Divider />
                                        </Grid>

                                        <Grid item xs={6} sm={4} md={3} lg={2}>
                                            <FormButton icon={<ClearIcon />}
                                                label="GENERAL.ACTIONS.CANCEL"
                                                onClick={() => handleCancelForm({ resetForm })} />
                                        </Grid>

                                        <Grid item xs={6} sm={4} md={3} lg={2}>
                                            <FormButton icon={<SubmitIcon />}
                                                label="GENERAL.ACTIONS.SAVE"
                                                type={buttonType.SUBMIT}
                                                disabled={!dirty || !isValid || isSubmitting} />
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Form>
                        )
                    }}
                </Formik>
            </CardContent>
        </Card>
    );
}

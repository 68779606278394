import { createSlice } from "@reduxjs/toolkit";
import {MESSAGE_TYPES} from "../../../../shared/constants";

const initialState = {
    loading: false,
    updateScrappingPrices: null,
    success: false,
    messages: [],
};
const { SUCCESS, ERROR } = MESSAGE_TYPES;

export const updateScrappingPricesSlice = createSlice({
    name: "updateScrappingPrices",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.messages.push({ id: action.payload.clientMessage, type: ERROR });
            state.loading = false;
            state.updateScrappingPrices= null;
        },
        startCall: (state, action) => {
            state.loading = true;
            state.success= false;
            state.messages = [];
        },
        removeMessages: (state) => {
            state.messages = [];
        },
        updateScrappingPricesFetched: (state, action) => {
            const { status } = action.payload;
            state.loading = false;
            state.success = action.payload.success;
            state.messages.push({ id: action.payload.message, type: SUCCESS });
        },
    }
});
import * as hotelService from "./hotelService";
import * as hotelMap from "./hotelMap";
import { hotelSlice } from "./hotelSlice";

const { actions } = hotelSlice;

export const validatedHotel = (id) => async dispatch => {
    if (id) {
        dispatch(actions.startCall());
        try {
            hotelService.validatedHotel(id).then(item => {
                hotelService.getHotelById(id).then(response => {
                    const hotel = response.data;
                    dispatch(actions.hotelFetchedValidated({ hotel }));
                })
            })
        } catch (error) {
            error.clientMessage = "HOTEL.MESSAGES.ACTIONS.ERROR.VALIDATED_HOTEL";
            dispatch(actions.catchError({ error }));
        }
    }
};

export const clearSuccessMessage = () => dispatch => {
    dispatch(actions.clearSuccessMessage());
};

export const clearErrorMessage = () => dispatch => {
    dispatch(actions.clearErrorMessage());
};

export const fetchHotels = queryParams => async dispatch => {
    dispatch(actions.startFetchHotelsCall());
    try {
        const response = await hotelService.findHotels(queryParams);
        dispatch(actions.hotelsFetched(hotelMap.fetchHotelsMap(response.data)));
    } catch (error) {
        error.clientMessage = "HOTEL_LIST.MESSAGES.ACTIONS.ERROR.LOAD_HOTELS";
        dispatch(actions.catchError({ error }));
    }
};

export const fetchHotelsOptions = queryParams => async dispatch => {
    dispatch(actions.hotelsOptionsFetched({ hotelsOptions: [] }));
    const { name } = queryParams.filter;
    if (name) {
        dispatch(actions.startCall());
        try {
            const response = await hotelService.findHotels(queryParams);
            dispatch(actions.hotelsOptionsFetched(hotelMap.hotelsOptionsMap(response.data)));
        } catch (error) {
            error.clientMessage = "HOTEL_LIST.MESSAGES.ACTIONS.ERROR.LOAD_HOTELS";
            dispatch(actions.catchError({ error }));
        }
    }
};

export const fetchHotel = id => async dispatch => {
    dispatch(actions.hotelFetched({ hotel: null }));
    if (id) {
        dispatch(actions.startCall());
        try {
            const response = await hotelService.getHotelById(id);
            const hotel = hotelMap.fetchHotelMap(response.data);
            dispatch(actions.hotelFetched({ hotel: hotel }));
        } catch (error) {
            error.clientMessage = "HOTEL_LIST.MESSAGES.ACTIONS.ERROR.LOAD_HOTEL";
            dispatch(actions.catchError({ error }));
        }
    }
};

export const createHotel = newHotel => async dispatch => {
    dispatch(actions.startCall());
    try {
        const response = await hotelService.createHotel(newHotel);
        const hotel = response.data;
        dispatch(actions.hotelCreated({ hotel }));
    } catch (error) {
        error.clientMessage = "HOTEL_LIST.MESSAGES.ACTIONS.ERROR.CREATE_HOTEL";
        dispatch(actions.catchError({ error }));
    }
};

export const updateHotel = modifiedHotel => async dispatch => {
    dispatch(actions.startCall());
    try {
        const response = await hotelService.updateHotel(modifiedHotel);
        const hotel = response.data;
        dispatch(actions.hotelUpdated({ hotel }));
    } catch (error) {
        error.clientMessage = "HOTEL_LIST.MESSAGES.ACTIONS.ERROR.UPDATE_HOTEL";
        dispatch(actions.catchError({ error }));
    }
};

export const updateConfiguration = request => async dispatch => {
    dispatch(actions.startCall());
    try {
        const response = await hotelService.updateConfiguration(request);
        const hotel = response.data;
        dispatch(actions.configurationUpdated({ hotel }));
    } catch (error) {
        error.clientMessage = "CONFIGURATION.MESSAGES.ACTIONS.ERROR.UPDATE_CONFIGURATION";
        dispatch(actions.catchError({ error }));
    }
}

import React from "react";
import { ChangePasswordUIProvider } from "./ChangePasswordUIContext";
import { ChangePasswordLayout } from "./ChangePasswordLayout";

export function ChangePasswordPage() {

    return (
        <ChangePasswordUIProvider >
            <ChangePasswordLayout />
        </ChangePasswordUIProvider>
    );
}

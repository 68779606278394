export function fetchHotelsMap(data) {
    return {
        hotels: data["hydra:member"],
        totalCount: data["hydra:totalItems"]
    };
}

export function fetchHotelMap(hotelResponseData) {
    const {
        id,
        slug,
        channels,
        modules,
        name,
        chain,
        stars,
        destination,
        latitude,
        longitude,
        email1,
        email2,
        email3,
        email4,
        telephone,
        telephoneContact,
        emailContact,
        address,
        validated,
        translations,
        latam,
        newAdmin,
        disparityAcceptPrices,
        currencyId,
        languageId,
        show,
        channelName
    } = hotelResponseData;
    return {
        id: id,
        slug: slug,
        channels: channels,
        modules: modules,
        name: name,
        chain: chain,
        stars: stars,
        destination: destination,
        latitude: latitude,
        longitude: longitude,
        email1: email1,
        email2: email2,
        email3: email3,
        email4: email4,
        telephone: telephone,
        telephoneContact: telephoneContact,
        emailContact: emailContact,
        address: address,
        validated: validated,
        translations: translations,
        latam: latam,
        newAdmin: newAdmin,
        disparityAcceptPrices: disparityAcceptPrices,
        currencyId: currencyId,
        languageId: languageId,
        show:show,
        channelName:channelName,
    };
}

export function hotelsOptionsMap(data) {
    const hotels = data["hydra:member"];
    return {
        hotelsOptions: hotels.map((hotel) => getHotelOption(hotel))
    };
}

function getHotelOption(hotel) {
    const { id, name, chain } = hotel;

    return {
        label: name,
        value: id,
        chain: chain
    };
}

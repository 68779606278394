export function dateFormat(date, locale) {
    return (date && locale)
        ? new Intl.DateTimeFormat(locale).format(date)
        : "";
}

export function customDateFormat(date, format, locale) {
    return (date && format && locale)
        ? new Intl.DateTimeFormat(locale, format).format(date)
        : "";
}

export function timeFormat(date) {
    return (date)
        ? date.toTimeString().substr(0, 5)
        : "";
}

export function dateTimeFormat(date, locale) {
    return dateFormat(date, locale) + " - " + timeFormat(date);
}

export function currencyFormat(number, locale, currency) {
    return (number && locale && currency)
        ? new Intl.NumberFormat(locale, { style: "currency", currency: currency }).format(number)
        : "0.00";
}

export const formModeType = {
    READ_ONLY: 'READ_ONLY',
    CREATE: 'CREATE',
    EDIT: 'EDIT'
};

export const textFieldType = {
    TEXT: 'text',
    NUMBER: 'number',
    EMAIL: 'email',
};

export const buttonType = {
    BUTTON: 'button',
    SUBMIT: 'submit'
};

export const autocompleteOptionsLength = 10;

export const autocompleteFieldInitialValue = { label: "", value: "" };

export function isReadOnlyForm(formMode) {
    return (formMode === formModeType.READ_ONLY);
}

export function isCreateForm(formMode) {
    return (formMode === formModeType.CREATE);
}

export function isEditForm(formMode) {
    return (formMode === formModeType.EDIT);
}

export const noErrorValidation = {
    error: false,
    errorMessage: ""
};

export const emptyFieldValidation = {
    error: true,
    errorMessage: "GENERAL.MESSAGES.ERROR.REQUIRED"
};

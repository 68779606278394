import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    photos: [],
    photosWidget: [],
    photosWidgetBackground: [],
    photo: undefined,
    photoWidget: undefined,
    photoWidgetBackground: undefined,
    error: null,
    success: null
};

export const photoSlice = createSlice({
    name: "photo",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.success = null;
            state.loading = false;
        },

        startFetchPhotosCall: (state, action) => {
            state.loading = true;
            state.error = null;
            state.success = null;            
            state.photos = [];
        },

        // findPhotos
        photosFetched: (state, action) => {
            state.loading = false;
            state.photos = action.payload.photos;
        },

        startCall: (state, action) => {
            state.loading = true;
            state.error = null;
            state.success = null;
        },

        // getPhotoById
        photoFetched: (state, action) => {
            state.loading = false;
            state.photo = action.payload.photo;
        },

        // uploadPhoto
        photoUploaded: (state, action) => {
            state.loading = false;
            state.photo = action.payload.photo;
        },

        // deletePhoto
        photoDeleted: (state, action) => {
            state.loading = false;
            state.photo = null;
        },

        photoUpdated: (state, action) => {
            state.loading = false;
            state.success = "PHOTO_GALLERY.MESSAGES.ACTIONS.SUCCESS.UPDATE_PHOTO";
            state.photo = action.payload.photo;
        },

        // findPhotosMR
        photosMRFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.photosWidget = action.payload.photosWidget;
        },

        // uploadPhotoMR
        photoMRUploaded: (state, action) => {
            state.loading = false;
            state.error = null;
            state.photosWidget = action.payload.images;
        },

        photoMRBackgroundUploaded: (state, action) => {
            state.loading = false;
            state.error = null;            
            state.photosWidgetBackground = action.payload.imagesBackground;
        },

    }
});

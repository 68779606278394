/* eslint-disable no-restricted-imports */
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ImageGallery from "../image-gallery/ImageGallery";
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles(theme => ({
    card: {
        width: '100%',
    },
}));

ImageGalleryPanel.defaultProps = {
    disableSelectFeaturedImage: false,
};

export function ImageGalleryPanel(
    {
        images,
        selectedImage,
        disableSelectFeaturedImage,
        onImageClick,
        onChangeFeaturedImage
    }
) {
    const classes = useStyles();

    return (
        <Card className={classes.card} variant='outlined'>
            <CardContent>
                <span>
                        <FormattedMessage id="PHOTO_GALLERY.MESSAGES.WARNING" />
                    </span>
                <ImageGallery images={images}
                    selectedImage={selectedImage}
                    disableSelectFeaturedImage={disableSelectFeaturedImage}
                    onImageClick={onImageClick}
                    onChangeFeaturedImage={onChangeFeaturedImage} />
            </CardContent>
        </Card>
    );
}

/* eslint-disable no-restricted-imports */
import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import NextIcon from '@material-ui/icons/ArrowForwardIos';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import SubmitIcon from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/HighlightOff';
import { FormButton } from "../controls/buttons/FormButton";
import { buttonType } from "../../_helpers/FormHelpers";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    form: {
        marginTop: theme.spacing(1),
    }
}));

export function GridWizardFormPanel(
    steps,
    {
        formValues,
        validationSchema,
        handleSubmitForm,
        handleCancelForm,
    }
) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);

    function handleBackButtonClick() {
        setActiveStep(activeStep - 1);
    }

    function handleNextButtonClick() {
        setActiveStep(activeStep + 1);
    }

    return (
        <Card className={classes.root}>
            <CardContent>
                <Formik initialValues={formValues}
                    validationSchema={validationSchema}
                    validateOnChange={true}
                    validateOnBlur={true}
                    enableReinitialize={true}
                    onSubmit={(values, { setSubmitting }) => handleSubmitForm(values, { setSubmitting })} >
                    {(props) => {
                        const {
                            dirty,
                            isValid,
                            isSubmitting,
                            resetForm,
                        } = props;

                        return (
                            <Form className={classes.form}>
                                <Grid container justify="center" spacing={2}>
                                    {steps.map((Step, index) => (
                                        <React.Fragment key={index}>
                                            {(activeStep === index) && (
                                                <Step {...props} />
                                            )}
                                        </React.Fragment>
                                    ))}
                                    <Grid container justify="flex-end" spacing={2} item xs={12}>
                                        <Grid item xs={12} >
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={4} sm={3} md={2} lg={2}>
                                            <FormButton icon={<ClearIcon />}
                                                label="GENERAL.ACTIONS.CANCEL"
                                                onClick={() => handleCancelForm({ resetForm })} />
                                        </Grid>
                                        {(activeStep > 0) && (
                                            <Grid item xs={4} sm={3} md={2} lg={2}>
                                                <FormButton icon={<BackIcon />}
                                                    label="GENERAL.ACTIONS.BACK"
                                                    onClick={() => handleBackButtonClick()} />
                                            </Grid>
                                        )}
                                        {(activeStep < (steps.length - 1)) && (
                                            <Grid item xs={4} sm={3} md={2} lg={2}>
                                                <FormButton endIcon={<NextIcon />}
                                                    label="GENERAL.ACTIONS.NEXT"
                                                    onClick={() => handleNextButtonClick()} />
                                            </Grid>
                                        )}
                                        {(activeStep === (steps.length - 1)) && (
                                            <Grid item xs={4} sm={3} md={2} lg={2}>
                                                <FormButton icon={<SubmitIcon />}
                                                    label="GENERAL.ACTIONS.SAVE"
                                                    type={buttonType.SUBMIT}
                                                    disabled={!dirty || !isValid || isSubmitting} />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Form>
                        )
                    }}
                </Formik>
            </CardContent>
        </Card>
    );
}

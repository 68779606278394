import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
    promotion: undefined,
    lastError: null
};

export const callTypes = {
    list: "list",
    action: "action"
};

export const promotionSlice = createSlice({
    name: "promotion",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },

        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },

        // getPromotionById
        promotionFetched: (state, action) => {
            state.actionsLoading = false;
            state.promotion = action.payload.promotion;
            state.error = null;
        },

        // findPromotions
        promotionsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },

        // createPromotion
        promotionCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.entities.push(action.payload.promotion);
        },

        // updatePromotion
        promotionUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.map(entity => {
                if (entity.id === action.payload.promotion.id) {
                    return action.payload.promotion;
                }
                return entity;
            });
        },
    }
});  

import React, { createContext, useContext } from "react";
import { useSelector } from "react-redux";

const ChangePasswordUIContext = createContext();

export function useChangePasswordUIContext() {
    return useContext(ChangePasswordUIContext);
}

export const ChangePasswordUIConsumer = ChangePasswordUIContext.Consumer;

export function ChangePasswordUIProvider({ children }) {
    const { user } = useSelector(state => state.auth);

    const value = {
        user: user
    };

    return (
        <ChangePasswordUIContext.Provider value={value} >
            {children}
        </ChangePasswordUIContext.Provider>
    );

}

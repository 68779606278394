import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    servicesHotels: {},
    servicesHotelsOptions: [],
    servicesHotel: null,
    success: null,
    error: null
};

export const servicesHotelSlice = createSlice({
    name: "servicesHotel",
    initialState: initialState,
    reducers: {

        restoreInitialServicesHotelState: (state, action) => {
            state = initialState;
        },

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startFetchServicesHotelsCall: (state, action) => {
            state.loading = true;
            state.success = null;
            state.error = null;
            state.servicesHotels = {};
        },

        // findServicesHotels
        servicesHotelsFetched: (state, action) => {
            state.loading = false;
            state.servicesHotels = action.payload.servicesHotels;
        },

        startServicesHotelsOptionsCall: (state, action) => {
            state.loading = true;
            state.success = null;
            state.error = null;
            state.servicesHotelsOptions = [];
        },

        servicesHotelsOptionsFetched: (state, actions) => {
            state.loading = false;
            state.servicesHotelsOptions = actions.payload.servicesHotelsOptions;
        },

        startCall: (state, action) => {
            state.loading = true;
            state.success = null;
            state.error = null;
        },

        // getServicesHotelById
        servicesHotelFetched: (state, action) => {
            state.loading = false;
            state.success = null;
            state.error = null;
            state.servicesHotel = action.payload.servicesHotel;
        },

        // createServicesHotel
        servicesHotelCreated: (state, action) => {
            state.loading = false;
            state.success = "SERVICES_HOTEL.MESSAGES.ACTIONS.SUCCESS.CREATE_SERVICES_HOTEL";
            state.servicesHotel = action.payload.servicesHotel;
        },

        // updateServicesHotel
        servicesHotelUpdated: (state, action) => {
            state.loading = false;
            state.success = "SERVICES_HOTEL.MESSAGES.ACTIONS.SUCCESS.UPDATE_SERVICES_HOTEL";
            state.servicesHotel = action.payload.servicesHotel;
        },
        
    }
});  

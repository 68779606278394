/* eslint-disable no-restricted-imports */
import React from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import FormLabel from "@material-ui/core/FormLabel";
import UploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/RemoveCircleOutline";
import Divider from '@material-ui/core/Divider';
import { UploadActionButton } from "../buttons/UploadActionButton";
import { ActionButton } from "../buttons/ActionButton";
import ImageGallery from "../../image-gallery/ImageGallery";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    divider: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    }
}));

FormImageGallery.defaultProps = {
    disableSelectFeaturedImage: false
};

export function FormImageGallery(
    {
        label,
        images,
        selectedImage,
        disableSelectFeaturedImage,
        onImageClick,
        onUploadPhotoButtonClick,
        onDeletePhotoButtonClick,
        onChangeFeaturedImage
    }
) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {(label) && (
                <FormLabel>
                    <FormattedMessage id={label} />
                </FormLabel>
            )}
            <Card variant='outlined'>
                <CardActions>
                    <UploadActionButton icon={<UploadIcon />}
                        label="GENERAL.ACTIONS.UPLOAD_PHOTO"
                        accept="image/*"
                        id="room-photo-upload"
                        multiple={false}
                        onChange={(event) => onUploadPhotoButtonClick(event)} />
                    <ActionButton icon={<DeleteIcon />}
                        label="GENERAL.ACTIONS.DELETE_PHOTO"
                        disabled={(selectedImage.id === -1)}
                        onClick={() => onDeletePhotoButtonClick()} />
                </CardActions>
                <CardContent style={{"padding-top":0}}>
                    <span>
                        <FormattedMessage id="PHOTO_GALLERY.MESSAGES.WARNING" />
                    </span>
                </CardContent>
                <Divider className={classes.divider} />
                <CardContent>
                    <ImageGallery images={images}
                        selectedImage={selectedImage}
                        disableSelectFeaturedImage={disableSelectFeaturedImage}
                        onImageClick={(image) => onImageClick(image)}
                        onChangeFeaturedImage={(image) => onChangeFeaturedImage(image)} />
                </CardContent>
            </Card>
        </div>
    );
}

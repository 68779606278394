import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    dispositions: [],
    disposition: null,
    error: null
};

export const meetingRoomDispositionSlice = createSlice({
    name: "meetingRoomDisposition",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
        },

        dispositionsFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.dispositions = action.payload.dispositions;
        },

        dispositionFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.disposition = action.payload.disposition;
        }

    }
});

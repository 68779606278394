import { createSlice } from "@reduxjs/toolkit";
import { MESSAGE_TYPES } from "../../../../shared/constants";

const initialState = {
    isLoadingCheckDisparity: false,
    isLoadingCheckLastScrappedPrice: false,
    successful: false,
    error: null,
    data: [],
    priceChecked:null,
    isPriceChecked:false,
};
const { SUCCESS, ERROR } = MESSAGE_TYPES;

export const disparitySlice = createSlice({
    name: "Disparity",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.isLoading = false;
            state.successful = false
        },
        startCallCheckDisparity: (state) => {
            state.isLoadingCheckDisparity = true;
            state.successful = false;
            state.data = [];
        },
        startCallCheckLastScrappedPrice: (state) => {
            state.isLoadingCheckLastScrappedPrice = true;
            state.successful = false;
            state.priceChecked = null;
            state.isPriceChecked = false;
        },
        disparityUploaded: (state, action) => {
            state.isLoadingCheckDisparity = false;
            state.successful = true;
            state.data = action.payload.data;
        },
        lastScrappedPriceChecked: (state, action) => {
            state.isLoadingCheckLastScrappedPrice = false;
            state.successful = true;
            state.priceChecked = action.payload.data;
            state.data = [];
            state.isPriceChecked = true;
        }
    }
});

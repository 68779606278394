import React from "react";
import NumberFormat from 'react-number-format';

AmountFormat.defaultProps = {
    thousandSeparator: true,
    allowNegative: false,
    isNumericString: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    currencySymbol: '$'
};

export function AmountFormat(props) {
    const {
        inputRef,
        onChange,
        thousandSeparator,
        allowNegative,
        isNumericString,
        decimalScale,
        fixedDecimalScale,
        currencySymbol,
        ...other
    } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={thousandSeparator}
            allowNegative={allowNegative}
            isNumericString={isNumericString}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
            prefix={`${currencySymbol} `}
        />
    );
}

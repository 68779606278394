import { createSlice } from "@reduxjs/toolkit";
import { callTypes } from "../../../../shared/constants";

const initialState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    hotelsContactPersons: [],
    hotelContactPersons: undefined,
    error: null,
    lastError: null
};

export const hotelContactPersonsSlice = createSlice({
    name: "hotelContactPersons",
    initialState: initialState,
    reducers: {
        restoreInitialHotelContactPersonsState: (state, action) => {
            state = initialState;
        },

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },

        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },

        // getHotelContactPersonsById
        hotelContactPersonsFetched: (state, action) => {
            state.actionsLoading = false;
            state.hotelContactPersons = action.payload.hotelContactPersons;
            state.error = null;
        },

        // findHotelContactPersons
        hotelContactPersonsFetched: (state, action) => {
            const { totalCount, hotelsContactPersons } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.hotelsContactPersons = hotelsContactPersons;
            state.totalCount = totalCount;
        },

        // createHotelContactPersons
        hotelContactPersonsCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.hotelsContactPersons.push(action.payload.hotelContactPersons);
        },

        // updateHotelContactPersons
        hotelContactPersonsUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.hotelsContactPersons = state.hotelsContactPersons.map(hotelContactPersons => {
                if (hotelContactPersons.id === action.payload.hotelContactPersons.id) {
                    return action.payload.hotelContactPersons;
                }
                return hotelContactPersons;
            });
        },
    }
});  

import destinyTableMock from "./destinyTableMock";
import { API_SERVER_URL } from "../../../shared/constants";

const DESTINIES_URL = API_SERVER_URL + "api/destinies";

export default function mockDestiny(mock) {     

    mock.onGet(DESTINIES_URL).reply(() => {
        return [200, destinyTableMock];
    });

}
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    billingData: null,
    success: null,
    error: null,
};

export const billingDataSlice = createSlice({
    name: "billingData",
    initialState: initialState,
    reducers: {

        clearSuccessMessage(state, action) {
            state.success = null;
        },

        clearErrorMessage(state, action) {
            state.error = null;
        },

        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
            state.success = null;
        },

        startFetchBillindDataCall: (state, action) => {
            state.loading = true;
            state.success = null;
            state.error = null;
            state.billingData = null;
        },

        billingDataFetched: (state, action) => {
            state.loading = false;
            state.billingData = action.payload.billingData;
        },

        startCall: (state, action) => {
            state.loading = true;
            state.success = null;
            state.error = null;
        },

        billingDataCreated: (state, action) => {
            state.loading = false;
            state.billingData = action.payload.billingData;
            state.success = "BILLING_DATA.MESSAGES.ACTIONS.SUCCESS.SAVE_BILLING_DATA";
        },

        billingDataUpdated: (state, action) => {
            state.loading = false;
            state.billingData = action.payload.billingData;
            state.success = "BILLING_DATA.MESSAGES.ACTIONS.SUCCESS.SAVE_BILLING_DATA";
        },

    }
});

import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import { GridFormPanel } from '../../../../partials';
import { ChangePasswordFormFields } from './ChangePasswordFormFields';
import * as actions from "../../_redux/password/passwordActions";

const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
};

const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
        .required("GENERAL.MESSAGES.ERROR.REQUIRED")
        .trim(),
    newPassword: Yup.string()
        .required("GENERAL.MESSAGES.ERROR.REQUIRED")
        .notOneOf([Yup.ref('currentPassword')], "ACCOUNT.PASSWORD.CHANGE_PASSWORD.ERROR.MESSAGE.EQUAL_CURRENT_AND_NEW_PASSWORD")
        .trim(),
    confirmPassword: Yup.string()
        .required("GENERAL.MESSAGES.ERROR.REQUIRED")
        .notOneOf([Yup.ref('currentPassword')], "ACCOUNT.PASSWORD.CHANGE_PASSWORD.ERROR.MESSAGE.EQUAL_CURRENT_AND_NEW_PASSWORD")
        .oneOf([Yup.ref('newPassword'), null], "ACCOUNT.PASSWORD.CHANGE_PASSWORD.ERROR.MESSAGE.NEW_AND_CONFIRM_PASSWORD_NOT_MATCH")
        .trim(),
});

export function ChangePasswordFormPanel() {
    const dispatch = useDispatch();

    const handleSubmitForm = (values, { setSubmitting }) => {
        const request = {
            actual_password: values.currentPassword,
            new_password: values.newPassword
        }
        dispatch(actions.changePassword(request))
            .then(() => {
                setSubmitting(false);
            });
    };

    const handleCancelForm = ({ resetForm }) => {
        resetForm();
    }

    return GridFormPanel(
        ChangePasswordFormFields,
        {
            formValues: initialValues,
            validationSchema,
            handleSubmitForm,
            handleCancelForm
        }
    );
}

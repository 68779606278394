export function buildMessage(messages, messageId, variant) {
    const message = ((messageId) && (!included(messages, messageId))) ? {
        variant: variant,
        messages: [messageId],
    } : null;

    return (message) ? messages.concat([message]) : messages;
}

export function included(messages, message) {
    return (messages && messages.length > 0)
        ? (messages.filter((msg) => (msg.messages.includes(message))).length === 0)
        : false;
}

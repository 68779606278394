/* eslint-disable no-restricted-imports */
import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { NoRecordsFoundMessage, rowsPerPageOptions } from "../../_helpers";

const useStyles = makeStyles(theme => ({
    table: {
        width: '100%',
        overflowX: 'auto',
        minWidth: 700,
    },
    tableRow: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
}));

const StyledTableCell = withStyles(theme => ({
    head: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: "bold",
    },
    body: {
        fontSize: theme.typography.pxToRem(14),
    },
}))(TableCell);

function EnhancedTableHead({ tableHead }) {
    return (
        <TableHead>
            <TableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                {(tableHead && (tableHead.length > 0)) && (
                    tableHead.map(cell => (
                        <StyledTableCell key={cell.id} align={'center'} padding={'default'} >
                            <FormattedMessage id={cell.label} />
                        </StyledTableCell>
                    ))
                )}
            </TableRow>
        </TableHead>
    );
}

function CollapsibleTableBody(
    {
        collapsibleTableHead,
        extendedEntity,
        selectedEntity,
        onChangeSelectedRow
    }
) {
    const isSelectedExtendedEntity = (extendedEntity.id === selectedEntity.id);

    return (
        <TableRow hover={true}
            tabIndex={-1}
            aria-checked={isSelectedExtendedEntity}
            selected={isSelectedExtendedEntity}
            onClick={() => onChangeSelectedRow(extendedEntity)} >
            <TableCell></TableCell>
            <TableCell padding="checkbox">
                <Radio name="collapsed-row-radio"
                    value={extendedEntity.id}
                    checked={isSelectedExtendedEntity}
                    onChange={() => onChangeSelectedRow(extendedEntity)} />
            </TableCell>
            {(collapsibleTableHead && (collapsibleTableHead.length > 0)) && (
                collapsibleTableHead.map((cell, columnIndex) => (
                    <StyledTableCell align={cell.dataAlignment}
                        key={extendedEntity.id.toString() + columnIndex.toString()} >
                        {extendedEntity[cell.id]}
                    </StyledTableCell>
                ))
            )}
        </TableRow>
    );
}

function CollapsibleRow(
    {
        tableHead,
        collapsibleTableHead,
        entity,
        selectedEntity,
        columnCount,
        collapsibleTableTitle,
        onChangeSelectedRow
    }
) {
    const classes = useStyles();
    const extendedEntities = entity.extendedEntities;
    const isEntitySelected = (entity.id === selectedEntity.id);
    const [isExpandedRow, setExpandedRow] = useState(false);

    function handleRowRadioChange(entity) {
        setExpandedRow(!isEntitySelected);
        onChangeSelectedRow(entity);
    }

    function handleRowClick(entity) {
        setExpandedRow(!isEntitySelected);
        onChangeSelectedRow(entity);
    }

    return (
        <React.Fragment>
            <TableRow hover={true}
                className={clsx({
                    [classes.tableRow]: (extendedEntities && (extendedEntities.length > 0))
                })}
                aria-checked={isEntitySelected}
                tabIndex={-1}
                selected={isEntitySelected}
                onClick={() => handleRowClick(entity)}>
                <TableCell>
                    {(extendedEntities && (extendedEntities.length > 0)) && (
                        <IconButton aria-label="expand row"
                            size="small" >
                            {isExpandedRow ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    )}
                </TableCell>
                <TableCell padding="checkbox">
                    <Radio name="row-radio"
                        value={entity.id}
                        checked={isEntitySelected}
                        onChange={() => handleRowRadioChange(entity)} />
                </TableCell>
                {(tableHead && (tableHead.length > 0)) && (
                    tableHead.map((cell, columnIndex) => (
                        <StyledTableCell align={cell.dataAlignment}
                            key={entity.id.toString() + columnIndex.toString()} >
                            {entity[cell.id]}
                        </StyledTableCell>
                    ))
                )}
            </TableRow>
            {(extendedEntities && (extendedEntities.length > 0)) && (
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columnCount}>
                        <Collapse in={isExpandedRow} timeout="auto" unmountOnExit>
                            <Box marginTop={2} marginBottom={2}>
                                {(collapsibleTableTitle) && (
                                    <Typography variant="h6" gutterBottom component="div">
                                        <FormattedMessage id={collapsibleTableTitle} />
                                    </Typography>
                                )}
                                <Table className={classes.table} size="medium" >
                                    <EnhancedTableHead tableHead={collapsibleTableHead} />
                                    <TableBody>
                                        {(extendedEntities && (extendedEntities.length > 0)) && (
                                            extendedEntities.map((extendedEntity) => (
                                                <CollapsibleTableBody collapsibleTableHead={collapsibleTableHead}
                                                    extendedEntity={extendedEntity}
                                                    selectedEntity={selectedEntity}
                                                    columnCount={columnCount}
                                                    onChangeSelectedRow={onChangeSelectedRow} />
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </React.Fragment>
    );
}

function CollapsibleSingleRowSelectionTable(
    {
        intl,
        tableHead,
        collapsibleTableHead,
        entities,
        selectedEntity,
        collapsibleTableTitle,
        paginationOptions,
        onChangeSelectedRow,
        onChangePage,
        onChangeRowsPerPage
    }
) {
    const classes = useStyles();
    const columnCount = (tableHead && (tableHead.length > 0)) ? (tableHead.length + 2) : 0;

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="medium" >
                <EnhancedTableHead tableHead={tableHead} />
                <TableBody>

                    {(entities && (entities.length > 0)) && (
                        entities.map((entity) => (
                            <CollapsibleRow key={entity.id}
                                tableHead={tableHead}
                                collapsibleTableHead={collapsibleTableHead}
                                entity={entity}
                                selectedEntity={selectedEntity}
                                columnCount={columnCount}
                                collapsibleTableTitle={collapsibleTableTitle}
                                onChangeSelectedRow={onChangeSelectedRow} />
                        ))
                    )}

                    {(entities && (entities.length === 0)) && (
                        <TableRow style={{ height: 100 }}>
                            <TableCell style={{ margin: 2 }} colSpan={columnCount} >
                                <NoRecordsFoundMessage entities={entities} />
                            </TableCell>
                        </TableRow>
                    )}

                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={paginationOptions.count}
                rowsPerPage={paginationOptions.rowsPerPage}
                page={paginationOptions.pageNumber}
                labelRowsPerPage={intl.formatMessage({ id: "TABLE.PAGINATOR.ROWS_PER_PAGE" })}
                labelDisplayedRows={(items) => intl.formatMessage(
                    { id: "TABLE.PAGINATOR.DISPLAYED_ROWS" },
                    { from: items.from, to: items.to, count: items.count },
                )}
                backIconButtonText={intl.formatMessage({ id: "TABLE.PAGINATOR.PREVIOUS_PAGE" })}
                nextIconButtonText={intl.formatMessage({ id: "TABLE.PAGINATOR.NEXT_PAGE" })}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage} />
        </TableContainer>
    );
}

export default injectIntl(CollapsibleSingleRowSelectionTable);

/* eslint-disable no-restricted-imports */
import React from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch';

export function FormSwitch({ name, label, checked, disabled, onChange }) {

    return (
        <FormControlLabel
            control={
                <Switch id={name}
                    name={name}
                    checked={checked}
                    disabled={disabled}
                    onChange={onChange}
                    color="secondary" />
            }
            label={label}
        />
    );
    
}

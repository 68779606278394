/* eslint-disable no-restricted-imports */
import React from "react";
import { injectIntl } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
    textField: {
        minWidth: 150,
    },
}));

function SearchTextField(
    {
        intl,
        name,
        label,
        value,
        type,
        onChange,
        onBlur
    }) {
    const classes = useStyles();

    return (
        <TextField className={classes.textField}
            id={name}
            name={name}
            variant="outlined"
            color="secondary"
            fullWidth={true}
            label={intl.formatMessage({ id: label })}
            value={value}
            type={(type) ? type : 'search'}
            onChange={onChange}
            onBlur={onBlur} />
    );
}

export default injectIntl(SearchTextField);

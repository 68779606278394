/* eslint-disable no-restricted-imports */
import React from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    actionButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),
        minWidth: 130,
        backgroundColor: ({ cancelled }) => `${cancelled === 1 ? '#d50000' : ''}`,
        "&:hover": {
            backgroundColor: ({ cancelled }) => `${cancelled === 1 ? '#d50000' : ''}`
          }
    },
}));

export function ActionButton({ icon, label, disabled, cancelled, onClick, href, target, color }) {
    const classes = useStyles({ cancelled });

    return (
        <Button className={classes.actionButton}
            variant="contained"
            color={color!== undefined ? color: "secondary"}
            startIcon={icon}
            disabled={disabled !== undefined ? disabled : false}
            href={href}
            target={target}
            onClick={onClick}>
            <FormattedMessage id={label} />
        </Button>
    );
}

import React from "react";

export function Checkbox({ name, checked, onChange, children }) {
  return (
    <>
      <label className="checkbox">
        <input type="checkbox" id={name} name={name} checked={checked} onChange={onChange} />
        {children}
        <span />
      </label>
    </>
  );
}

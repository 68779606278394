/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useSelector} from "react-redux";
import {useLocation} from "react-router";
import {NavLink, Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import {userHasRole} from "../../../../../app/_helpers";
import {ROLES, displayRoles, WEB_EXTRANET_URL, BYHOURS_WEB} from "../../../../../app/shared/constants";
import {Badge} from 'react-bootstrap'
import {useLang} from "../../../../i18n";

export function AsideMenuList({layoutProps}) {
    const locale = useLang();
    const {hotel} = useSelector(state => state.hotelPersist);
    const {modules} = hotel;
    const arrModulesId = (modules) ? modules.map((module) => module.id) : [];
    const {user} = useSelector(state => state.auth);
    const EXTRANET_HOTEL_URL = WEB_EXTRANET_URL + `/EasyAvailability/view/${hotel.id}`;
    const ADMNI_HOTEL_URL = WEB_EXTRANET_URL + `/hotels/contactDetails/${hotel.id}`;
    const lang = (locale === 'pt') ? 'en': locale;
    const BYHOURS_WEB_URL = BYHOURS_WEB + `${lang}/${hotel.slug}-${hotel.id}.html` ;
    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url)
            ? " menu-item-active menu-item-open "
            : "";
    };
    return (
        <React.Fragment>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>

                {((userHasRole(user.roles, ROLES.SUPER_ADMIN) || userHasRole(user.roles, ROLES.COMPANY_MANAGER))
                    && (checkIsActive(location, "/mailsSenders/list") || checkIsActive(location, "/hotel/list") || checkIsActive(location, "/hotel/create") || checkIsActive(location, "/bill/list") || checkIsActive(location, "user/list") || checkIsActive(location, "/change-password")))
                && (
                    <React.Fragment>
                        <div className="p-3">

                            <div className="pr-3 mr-n3">
                                <div className="d-flex align-items-center mt-3">
                                    <div className="d-flex flex-column">
                                        <div className="font-weight-bold font-size-h5">
                                            {user.userName}
                                        </div>
                                        {user.roles.length > 0 && (user.roles.map((role, index) => {
                                            return (
                                                <div className="text-muted mt-1" key={index}>
                                                    {displayRoles[role]}
                                                </div>
                                            );
                                        }))}
                                        <div className="text-muted p-0 pb-1">
                                            {user.email}
                                        </div>
                                        <Link to="/change-password"
                                              className="text-dark-50 text-hover-primary my-3 mr-2">
                                            <FormattedMessage
                                                id="ACCOUNT.PASSWORD.CHANGE_PASSWORD.LINK.CHANGE_PASSWORD"/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                {(((userHasRole(user.roles, ROLES.SUPER_ADMIN) || userHasRole(user.roles, ROLES.COMPANY_MANAGER))
                    && !(checkIsActive(location, "/mailsSenders/list") || checkIsActive(location, "/hotel/list") || checkIsActive(location, "/hotel/create") || checkIsActive(location, "/bill/list") || checkIsActive(location, "user/list") || checkIsActive(location, "/change-password")))
                    || (userHasRole(user.roles, ROLES.HOTEL_MANAGER)))
                && (
                    <React.Fragment>
                        <li>
                            <span className="text-center text-white">
                                <h5>{hotel.name}</h5>
                            </span>
                        </li>
                        {/* Admin Url, Extranet Url, Web URL for SUPER_ADMIN */}
                        {
                            (userHasRole(user.roles, ROLES.SUPER_ADMIN)) &&
                            (
                                <>
                                    <li >
                                        <a  className="text-white" href={ADMNI_HOTEL_URL} target="_blank" >
                                            <span className="menu-text font-size-xs"> <FormattedMessage id="MENU.ADMIN_URL"/></span>
                                        </a>
                                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                                        <a className="text-white" href={EXTRANET_HOTEL_URL} target="_blank" >
                                            <span className="menu-text font-size-xs"><FormattedMessage id="MENU.EXTRANET_URL"/></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="text-white" href={BYHOURS_WEB_URL} target="_blank">
                                            <span className="menu-text font-size-xs"><FormattedMessage id="MENU.WEB_URL"/></span>
                                        </a>
                                    </li>
                                </>
                            )
                        }
                        {/* WEB Url  for hotel and campany manager  */}
                        {
                            (userHasRole(user.roles, ROLES.COMPANY_MANAGER) || userHasRole(user.roles, ROLES.HOTEL_MANAGER)) &&
                            (
                                <li className="text-center">
                                    <a className="text-center text-white font-size-xs" href={BYHOURS_WEB_URL} target="_blank">
                                        <span className="menu-text"><FormattedMessage id="MENU.WEB_URL"/></span>
                                    </a>
                                </li>
                            )
                        }
                        {/* Rooms */}
                        {(arrModulesId && arrModulesId.includes(1)) && (
                            <li className={`menu-item menu-item-submenu ${getMenuItemActive("/hotel/rooms")}`}
                                aria-haspopup="true"
                                data-menu-toggle="hover">
                                <NavLink className="menu-link menu-toggle" to="/hotel/rooms">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Bed.svg")}/>
                    </span>
                                    <span className="menu-text">
                      <FormattedMessage id="MENU.HOTELS.ROOMS"/>
                    </span>
                                    <i className="menu-arrow"/>
                                </NavLink>
                                <div className="menu-submenu ">
                                    <ul className="menu-subnav">
                                        <ul className="menu-subnav">
                                            <li className="menu-item  menu-item-parent"
                                                aria-haspopup="true">
                          <span className="menu-link">
                            <span className="menu-text">
                              <FormattedMessage id="MENU.HOTELS.ROOMS"/>
                            </span>
                          </span>
                                            </li>
                                            {/* Rooms - Availability and Rates */}
                                            <li className={`menu-item menu-item-submenu ${getMenuItemActive("/hotel/rooms/availability-rates")}`}
                                                aria-haspopup="true"
                                                data-menu-toggle="hover">
                                                <NavLink className="menu-link menu-toggle"
                                                         to="/hotel/rooms/availability-rates">
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span/>
                                                    </i>
                                                    <span className="menu-text">
                              <FormattedMessage id="MENU.HOTELS.ROOMS.AVAILABILITY_AND_RATES"/>
                            </span>
                                                    <i className="menu-arrow"/>
                                                </NavLink>
                                                <div className="menu-submenu">
                                                    <i className="menu-arrow"/>
                                                    <ul className="menu-subnav">

                                                        {/* Rooms - Availability and Rates - General View */}
                                                        <li className={`menu-item ${getMenuItemActive("/hotel/rooms/availability-rates/general-view")}`}
                                                            aria-haspopup="true">
                                                            <NavLink className="menu-link"
                                                                     to="/hotel/rooms/availability-rates/general-view">
                                                                <i className="menu-bullet menu-bullet-dot">
                                                                    <span/>
                                                                </i>
                                                                <span className="menu-text">
                                    <FormattedMessage id="MENU.HOTELS.ROOMS.AVAILABILITY_AND_RATES.GENERAL_VIEW"/>
                                  </span>
                                                            </NavLink>
                                                        </li>

                                                        {/* Rooms - Availability and Rates - Upload Availability */}
                                                        <li className={`menu-item ${getMenuItemActive("/hotel/rooms/availability-rates/upload-availability")}`}
                                                            aria-haspopup="true">
                                                            <NavLink className="menu-link"
                                                                     to="/hotel/rooms/availability-rates/upload-availability">
                                                                <i className="menu-bullet menu-bullet-dot">
                                                                    <span/>
                                                                </i>
                                                                <span className="menu-text">
                                    <FormattedMessage
                                        id="MENU.HOTELS.ROOMS.AVAILABILITY_AND_RATES.UPLOAD_AVAILABILITY"/>
                                  </span>
                                                            </NavLink>
                                                        </li>

                                                        {/* Rooms - Availability and Rates - Massive Load */}
                                                        <li className={`menu-item ${getMenuItemActive("/hotel/rooms/availability-rates/massive-load")}`}
                                                            aria-haspopup="true">
                                                            <NavLink className="menu-link"
                                                                     to="/hotel/rooms/availability-rates/massive-load">
                                                                <i className="menu-bullet menu-bullet-dot">
                                                                    <span/>
                                                                </i>
                                                                <span className="menu-text">
                                    <FormattedMessage id="MENU.HOTELS.ROOMS.AVAILABILITY_AND_RATES.MASSIVE_LOAD"/>
                                  </span>
                                                            </NavLink>
                                                        </li>
                                                        {/* Rooms - Availability and Rates - Prices calendar */}
                                                        {(hotel.newAdmin) &&
                                                            (
                                                                <li className={`menu-item ${getMenuItemActive("/hotel/rooms/availability-rates/prices-calendar")}`}
                                                                    aria-haspopup="true">
                                                                    <NavLink className="menu-link"
                                                                             to="/hotel/rooms/availability-rates/prices-calendar">
                                                                        <i className="menu-bullet menu-bullet-dot">
                                                                            <span/>
                                                                        </i>
                                                                        <span className="menu-text">
                                    <FormattedMessage id="MENU.HOTELS.ROOMS.AVAILABILITY_AND_RATES.PRICES_CALENDAR"/>
                                  </span>
                                                                    </NavLink>
                                                                </li>)
                                                        }
                                                        {/* Rooms - Availability and Rates - Prices disparity */}
                                                        {(hotel.newAdmin) &&
                                                            (
                                                                <li className={`menu-item ${getMenuItemActive("/hotel/rooms/availability-rates/prices-disparity")}`}
                                                                    aria-haspopup="true">
                                                                    <NavLink className="menu-link"
                                                                             to="/hotel/rooms/availability-rates/prices-disparity">
                                                                        <i className="menu-bullet menu-bullet-dot">
                                                                            <span/>
                                                                        </i>
                                                                        <span className="menu-text">
                                    <FormattedMessage id="MENU.HOTELS.ROOMS.AVAILABILITY_AND_RATES.DISPARITY"/>
                                  </span>
                                                                    </NavLink>
                                                                </li>)
                                                        }
                                                    </ul>
                                                </div>
                                            </li>
                                            {/* Rooms - Promotion */}
                                            <li className={`menu-item ${getMenuItemActive("/hotel/rooms/Promotion")}`}
                                                aria-haspopup="true">
                                                <NavLink className="menu-link" to="/hotel/rooms/Promotion">
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span/>
                                                    </i>
                                                    <span className="menu-text"><FormattedMessage id="MENU.HOTELS.ROOMS.PROMOTION"/></span>
                                                </NavLink>
                                            </li>

                                            {/* Rooms - Booking */}
                                            <li className={`menu-item ${getMenuItemActive("/hotel/rooms/rooms-booking/list")}`}
                                                aria-haspopup="true">
                                                <NavLink className="menu-link" to="/hotel/rooms/rooms-booking/list">
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span/>
                                                    </i>
                                                    <span className="menu-text">
                              <FormattedMessage id="MENU.HOTELS.ROOMS.BOOKING"/>
                            </span>
                                                </NavLink>
                                            </li>

                                            {/* Rooms - Dashboard */}
                                            <li className={`menu-item ${getMenuItemActive("/hotel/rooms/dashboard")}`}
                                                aria-haspopup="true">
                                                <NavLink className="menu-link" to="/hotel/rooms/dashboard">
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span/>
                                                    </i>
                                                    <span className="menu-text">
                              <FormattedMessage id="MENU.HOTELS.ROOMS.DASHBOARD"/>
                            </span>
                                                </NavLink>
                                            </li>

                                            {/* Hotel Services */}
                                            <li className={`menu-item ${getMenuItemActive("/hotel/rooms/services-and-extras")}`}
                                                aria-haspopup="true">
                                                <NavLink className="menu-link" to="/hotel/rooms/services-and-extras">
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span/>
                                                    </i>
                                                    <span className="menu-text">
                            <FormattedMessage id="MENU.HOTELS.GENERAL.SERVICES_AND_EXTRAS"/>
                          </span>
                                                </NavLink>
                                            </li>
                                            {/* Rooms - Room Types */}
                                            <li className={`menu-item ${getMenuItemActive("/hotel/rooms/room-types")}`}
                                                aria-haspopup="true">
                                                <NavLink className="menu-link" to="/hotel/rooms/room-types">
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span/>
                                                    </i>
                                                    <span className="menu-text">
                              <FormattedMessage id="MENU.HOTELS.ROOMS.ROOM_TYPES"/>
                            </span>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </ul>
                                </div>
                            </li>
                        )}

                        {/* Meeting Rooms */}
                        {(arrModulesId && arrModulesId.includes(2)) && (
                            <li className={`menu-item menu-item-submenu ${getMenuItemActive("/hotel/meeting-room")}`}
                                aria-haspopup="true"
                                data-menu-toggle="hover">
                                <NavLink className="menu-link menu-toggle" to="/hotel/meeting-room">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/MeetingRoom.svg")}/>
                    </span>
                                    <span className="menu-text">
                      <FormattedMessage id="MENU.HOTELS.MEETING_ROOMS"/>
                    </span>
                                    <i className="menu-arrow"/>
                                </NavLink>
                                <div className="menu-submenu ">
                                    <ul className="menu-subnav">
                                        <ul className="menu-subnav">
                                            <li className="menu-item  menu-item-parent"
                                                aria-haspopup="true">
                          <span className="menu-link">
                            <span className="menu-text">
                              <FormattedMessage id="MENU.HOTELS.MEETING_ROOMS"/>
                            </span>
                          </span>
                                            </li>


                                            {/* Meeting Rooms - Availability and Rates */}
                                            <li className={`menu-item menu-item-submenu ${getMenuItemActive("/hotel/meeting-room/availability-rates")}`}
                                                aria-haspopup="true"
                                                data-menu-toggle="hover">
                                                <NavLink className="menu-link menu-toggle"
                                                         to="/hotel/meeting-room/availability-rates">
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span/>
                                                    </i>
                                                    <span className="menu-text">
                              <FormattedMessage id="MENU.HOTELS.MEETING_ROOMS.AVAILABILITY_AND_RATES"/>
                            </span>
                                                    <i className="menu-arrow"/>
                                                </NavLink>
                                                <div className="menu-submenu">
                                                    <i className="menu-arrow"/>
                                                    <ul className="menu-subnav">

                                                        {/* meeting Rooms - Availability and Rates - General View */}
                                                        <li className={`menu-item ${getMenuItemActive("/hotel/meeting-room/availability-rates/general-view")}`}
                                                            aria-haspopup="true">
                                                            <NavLink className="menu-link"
                                                                     to="/hotel/meeting-room/availability-rates/general-view">
                                                                <i className="menu-bullet menu-bullet-dot">
                                                                    <span/>
                                                                </i>
                                                                <span className="menu-text">
                                    <FormattedMessage
                                        id="MENU.HOTELS.MEETING_ROOMS.AVAILABILITY_AND_RATES.GENERAL_VIEW"/>
                                  </span>
                                                            </NavLink>
                                                        </li>

                                                        {/* meeting Rooms - Availability and Rates - Upload Availability */}
                                                        <li className={`menu-item ${getMenuItemActive("/hotel/meeting-room/availability-rates/upload-availability")}`}
                                                            aria-haspopup="true">
                                                            <NavLink className="menu-link"
                                                                     to="/hotel/meeting-room/availability-rates/upload-availability">
                                                                <i className="menu-bullet menu-bullet-dot">
                                                                    <span/>
                                                                </i>
                                                                <span className="menu-text">
                                    <FormattedMessage
                                        id="MENU.HOTELS.MEETING_ROOMS.AVAILABILITY_AND_RATES.UPLOAD_AVAILABILITY"/>
                                  </span>
                                                            </NavLink>
                                                        </li>

                                                        {/* meeting Rooms - Availability and Rates - Massive Load */}
                                                        <li className={`menu-item ${getMenuItemActive("/hotel/meeting-room/availability-rates/massive-load")}`}
                                                            aria-haspopup="true">
                                                            <NavLink className="menu-link"
                                                                     to="/hotel/meeting-room/availability-rates/massive-load">
                                                                <i className="menu-bullet menu-bullet-dot">
                                                                    <span/>
                                                                </i>
                                                                <span className="menu-text">
                                    <FormattedMessage
                                        id="MENU.HOTELS.MEETING_ROOMS.AVAILABILITY_AND_RATES.MASSIVE_LOAD"/>
                                  </span>
                                                            </NavLink>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>


                                            {/* Meeting Rooms - Booking */}
                                            <li className={`menu-item ${getMenuItemActive("/hotel/meeting-room/meeting-room-booking/list")}`}
                                                aria-haspopup="true">
                                                <NavLink className="menu-link"
                                                         to="/hotel/meeting-room/meeting-room-booking/list">
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span/>
                                                    </i>
                                                    <span className="menu-text">
                              <FormattedMessage id="MENU.HOTELS.ROOMS.BOOKING"/>
                            </span>
                                                </NavLink>
                                            </li>

                                            {/* Meeting Rooms - Dashboard */}
                                            <li className={`menu-item ${getMenuItemActive("/hotel/meeting-room/dashboard")}`}
                                                aria-haspopup="true">
                                                <NavLink className="menu-link" to="/hotel/meeting-room/dashboard">
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span/>
                                                    </i>
                                                    <span className="menu-text">
                              <FormattedMessage id="MENU.HOTELS.ROOMS.DASHBOARD"/>
                            </span>
                                                </NavLink>
                                            </li>

                                            {/* meeting Rooms - Services*/}
                                            <li className={`menu-item ${getMenuItemActive("/hotel/meeting-room/services-hotel")}`}
                                                aria-haspopup="true">
                                                <NavLink className="menu-link" to="/hotel/meeting-room/services-hotel">
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span/>
                                                    </i>
                                                    <span className="menu-text">
                              <FormattedMessage id="MENU.HOTELS.GENERAL.SERVICES_AND_EXTRAS"/>
                            </span>
                                                </NavLink>
                                            </li>

                                            {/* Meeting Rooms - Meeting Rooms Types */}
                                            <li className={`menu-item ${getMenuItemActive("/hotel/meeting-room/meeting-room-types")}`}
                                                aria-haspopup="true">
                                                <NavLink className="menu-link"
                                                         to="/hotel/meeting-room/meeting-room-types">
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span/>
                                                    </i>
                                                    <span className="menu-text">
                              <FormattedMessage id="MENU.HOTELS.MEETING_ROOMS.MEETING_ROOMS_TYPES"/>
                            </span>
                                                </NavLink>
                                            </li>

                                        </ul>
                                    </ul>
                                </div>
                            </li>
                        )}
                        {/* General */}
                        <li className={`menu-item menu-item-submenu ${getMenuItemActive("/hotel/general")}`}
                            aria-haspopup="true"
                            data-menu-toggle="hover">
                            <NavLink className="menu-link menu-toggle" to="/hotel/general">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}/>
                  </span>
                                <span className="menu-text">
                    <FormattedMessage id="MENU.HOTELS.GENERAL"/>
                  </span>
                                <i className="menu-arrow"/>
                            </NavLink>
                            <div className="menu-submenu ">
                                <ul className="menu-subnav">
                                    <ul className="menu-subnav">
                                        <li className="menu-item  menu-item-parent"
                                            aria-haspopup="true">
                        <span className="menu-link">
                          <span className="menu-text">
                            <FormattedMessage id="MENU.HOTELS.GENERAL"/>
                          </span>
                        </span>
                                        </li>

                                        {/* General Information */}
                                        <li className={`menu-item ${getMenuItemActive("/hotel/general/general-information")}`}
                                            aria-haspopup="true">
                                            <NavLink className="menu-link" to="/hotel/general/general-information">
                                                <i className="menu-bullet menu-bullet-dot">
                                                    <span/>
                                                </i>
                                                <span className="menu-text">
                            <FormattedMessage id="MENU.HOTELS.GENERAL.GENERAL_INFORMATION"/>
                          </span>
                                            </NavLink>
                                        </li>

                                        {/* Photos */}
                                        <li className={`menu-item ${getMenuItemActive("/hotel/general/photos")}`}
                                            aria-haspopup="true">
                                            <NavLink className="menu-link" to="/hotel/general/photos">
                                                <i className="menu-bullet menu-bullet-dot">
                                                    <span/>
                                                </i>
                                                <span className="menu-text">
                            <FormattedMessage id="MENU.HOTELS.GENERAL.PHOTOS"/>
                          </span>
                                            </NavLink>
                                        </li>

                                        {/* Email Templates */}
                                        {
                                            userHasRole(user.roles, ROLES.SUPER_ADMIN) && (
                                                <li className={`menu-item ${getMenuItemActive("/hotel/general/email-template/list")}`}
                                                    aria-haspopup="true">
                                                    <NavLink className="menu-link"
                                                             to="/hotel/general/email-template/list">
                                                        <i className="menu-bullet menu-bullet-dot">
                                                            <span/>
                                                        </i>
                                                        <span className="menu-text">
                                <FormattedMessage id="MENU.HOTELS.GENERAL.EMAIL_TEMPLATE"/>
                              </span>
                                                    </NavLink>
                                                </li>
                                            )
                                        }
                                        {/* Setting */}
                                        {
                                            (userHasRole(user.roles, ROLES.SUPER_ADMIN)) &&
                                            (
                                                <li className={`menu-item ${getMenuItemActive("/hotel/generalt/configuration")}`}
                                                    aria-haspopup="true">
                                                    <NavLink className="menu-link" to="/hotel/general/configuration">
                                                        <i className="menu-bullet menu-bullet-dot">
                                                            <span/>
                                                        </i>
                                                        <span className="menu-text">
                                                        <FormattedMessage id="MENU.HOTELS.MY_ACCOUNT.CONFIGURATION"/>
                                                    </span>
                                                    </NavLink>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </ul>
                            </div>
                        </li>
                        {/* My Account */}
                        <li className={`menu-item menu-item-submenu ${getMenuItemActive("/hotel/my-account")}`}
                            aria-haspopup="true"
                            data-menu-toggle="hover">
                            <NavLink className="menu-link menu-toggle" to="/hotel/my-account">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
                  </span>
                                <span className="menu-text">
                    <FormattedMessage id="MENU.HOTELS.MY_ACCOUNT"/>
                  </span>
                                <i className="menu-arrow"/>
                            </NavLink>
                            <div className="menu-submenu ">
                                <ul className="menu-subnav">
                                    <ul className="menu-subnav">
                                        <li className="menu-item  menu-item-parent"
                                            aria-haspopup="true">
                        <span className="menu-link">
                          <span className="menu-text">
                            <FormattedMessage id="MENU.HOTELS.MY_ACCOUNT"/>
                          </span>
                        </span>
                                        </li>

                                        {/* My Bills */}
                                        <li className={`menu-item ${getMenuItemActive("/hotel/my-account/my-bills")}`}
                                            aria-haspopup="true">
                                            <NavLink className="menu-link" to="/hotel/my-account/my-bills">
                                                <i className="menu-bullet menu-bullet-dot">
                                                    <span/>
                                                </i>
                                                <span className="menu-text">
                            <FormattedMessage id="MENU.HOTELS.MY_ACCOUNT.MY_INVOICES"/>
                          </span>
                                            </NavLink>
                                        </li>

                                        {/* My Invoicing Data */}
                                        <li className={`menu-item ${getMenuItemActive("/hotel/my-account/my-invoicing-data")}`}
                                            aria-haspopup="true">
                                            <NavLink className="menu-link" to="/hotel/my-account/my-invoicing-data/">
                                                <i className="menu-bullet menu-bullet-dot">
                                                    <span/>
                                                </i>
                                                <span className="menu-text">
                            <FormattedMessage id="MENU.HOTELS.MY_ACCOUNT.MY_INVOICING_DATA"/>
                          </span>
                                            </NavLink>
                                        </li>

                                    </ul>
                                </ul>
                            </div>
                        </li>

                        {/* Widget */}
                        <li className={`menu-item menu-item-submenu ${getMenuItemActive("/hotel/widget")}`}
                            aria-haspopup="true"
                            data-menu-toggle="hover">
                            <NavLink className="menu-link menu-toggle" to="/hotel/widget">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Puzzle.svg")}/>
                  </span>
                                <span className="menu-text">
                    <FormattedMessage id="MENU.HOTELS.WIDGET"/>
                  </span>
                                <i className="menu-arrow"/>
                            </NavLink>
                            <div className="menu-submenu ">
                                <ul className="menu-subnav">
                                    <ul className="menu-subnav">
                                        <li className="menu-item  menu-item-parent"
                                            aria-haspopup="true">
                        <span className="menu-link">
                          <span className="menu-text">
                            <FormattedMessage id="MENU.HOTELS.WIDGET"/>
                          </span>
                        </span>
                                        </li>

                                        {/* Widgets Configuration */}
                                        <li className={`menu-item ${getMenuItemActive("/hotel/widget/widgets-configuration")}`}
                                            aria-haspopup="true">
                                            <NavLink className="menu-link" to="/hotel/widget/widgets-configuration">
                                                <i className="menu-bullet menu-bullet-dot">
                                                    <span/>
                                                </i>
                                                <span className="menu-text">
                            <FormattedMessage id="MENU.HOTELS.WIDGET.WIDGET_ROOM_CONFIGURATION"/>
                          </span>
                                            </NavLink>
                                        </li>

                                        <li className={`menu-item ${getMenuItemActive("/hotel/widget/widgets-meeting-room")}`}
                                            aria-haspopup="true">
                                            <NavLink className="menu-link" to="/hotel/widget/widgets-meeting-room">
                                                <i className="menu-bullet menu-bullet-dot">
                                                    <span/>
                                                </i>
                                                <span className="menu-text">
                            <FormattedMessage id="MENU.HOTELS.WIDGET.WIDGET__MEETING_ROOM_CONFIGURATION"/>
                          </span>
                                            </NavLink>
                                        </li>

                                        {/* Front Configuration */}
                                        <li className={`menu-item ${getMenuItemActive("/hotel/widget/front-configuration")}`}
                                            aria-haspopup="true">
                                            <NavLink className="menu-link" to="/hotel/widget/front-configuration">
                                                <i className="menu-bullet menu-bullet-dot">
                                                    <span/>
                                                </i>
                                                <span className="menu-text">
                            <FormattedMessage id="MENU.HOTELS.WIDGET.FRONT_CONFIGURATION"/>
                          </span>
                                            </NavLink>
                                        </li>

                                    </ul>
                                </ul>
                            </div>
                        </li>

                        {/* Support */}
                        <li className={`menu-item menu-item-submenu ${getMenuItemActive("/hotel/support")}`}
                            aria-haspopup="true"
                            data-menu-toggle="hover">
                            <NavLink className="menu-link menu-toggle" to="/hotel/support">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}/>
                  </span>
                                <span className="menu-text">
                    <FormattedMessage id="MENU.HOTELS.SUPPORT"/>
                  </span>
                                <i className="menu-arrow"/>
                            </NavLink>
                            <div className="menu-submenu ">
                                <ul className="menu-subnav">
                                    <ul className="menu-subnav">
                                        <li className="menu-item  menu-item-parent"
                                            aria-haspopup="true">
                        <span className="menu-link">
                          <span className="menu-text">
                            <FormattedMessage id="MENU.HOTELS.SUPPORT"/>
                          </span>
                        </span>
                                        </li>
                                        {/* Contact */}
                                        <li className={`menu-item ${getMenuItemActive("/hotel/support/contact")}`}
                                            aria-haspopup="true">
                                            <NavLink className="menu-link" to="/hotel/support/contact">
                                                <i className="menu-bullet menu-bullet-dot">
                                                    <span/>
                                                </i>
                                                <span className="menu-text">
                            <FormattedMessage id="MENU.HOTELS.SUPPORT.CONTACT"/>
                          </span>
                                            </NavLink>
                                        </li>

                                        {/* User manual */}
                                        <li className={`menu-item ${getMenuItemActive("/hotel/support/user-manual")}`}
                                            aria-haspopup="true">
                                            <NavLink className="menu-link" to="/hotel/support/user-manual">
                                                <i className="menu-bullet menu-bullet-dot">
                                                    <span/>
                                                </i>
                                                <span className="menu-text">
                            <FormattedMessage id="MENU.HOTELS.SUPPORT.USER_MANUAL"/>
                          </span>
                                            </NavLink>
                                        </li>

                                    </ul>
                                </ul>
                            </div>
                        </li>


                    </React.Fragment>
                )}
                {(hotel && hotel.show===2) && (
                    <React.Fragment>
                        <li className={`menu-item my-4`}
                            aria-haspopup="true">
                            <a href="#" className={'menu-link'}>
                                <a href="#" className={'btn'} style={{
                                    'background-color': "#FF5E1E",
                                    'border-color': "#FF5E1E",
                                    "color": "white",
                                    "borderRadius": 0
                                }}>
                                    OnBoarding
                                </a>
                            </a>

                        </li>
                    </React.Fragment>)
                }


                {(userHasRole(user.roles, ROLES.HOTEL_MANAGER)) && (
                    <React.Fragment>
                        {/* Logout */}
                        <li className={`menu-item ${getMenuItemActive("/logout")}`}
                            aria-haspopup="true">
                            <NavLink className="menu-link" to="/logout">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Door-open.svg")}/>
                </span>
                                <span className="menu-text">
                  <FormattedMessage id="AUTH.LOGOUT.BUTTON"/>
                </span>
                            </NavLink>
                        </li>
                    </React.Fragment>
                )}

            </ul>
            {/* end::Menu Nav */}
        </React.Fragment>
    );
}

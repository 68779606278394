import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    monthStatistics: {},
    monthIncomes: [],
    monthTotals: [],
    success: null,
    error: null,
    loading: false,
    monthBookings: [],
    todayBookings: [],
};

export const statisticsSlice = createSlice({
    name: "hotelStatistics",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = { ...action.payload.error };
            state.loading = false;
        },

        startCall: (state, action) => {
            state.success = null;
            state.error = null;
            state.loading = true;
        },
        startBooksCall: (state, action) => {
            state.success = null;
            state.error = null;
            state.loading = true;
        },

        roomsStatisticsFetched: (state, action) => {
            state.loading = false;
            state.monthStatistics = action.payload;
            state.error = null;
        },
        roomsMonthIncomeFetched: (state, action) => {
            state.loading = false;
            state.monthIncomes = action.payload;
            state.error = null;
        },
        roomsMonthReservationsFetched: (state, action) => {
            state.loading = false;
            state.monthTotals = action.payload;
            state.error = null;
        },
        bookingsFetched: (state, action) => {
            state.loading = false;
            state.monthBookings = action.payload;
            state.error = null;
        },
        todayBookingsFetched: (state, action) => {
            state.loading = false;
            state.todayBookings = action.payload;
            state.error = null;
        },

    }
});  

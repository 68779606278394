/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import KTLayoutAsideToggle from "../../../_metronic/_assets/js/layout/base/aside-toggle";
import { KTUtil } from "../../../_metronic/_assets/js/components/util";
import { KTCookie } from "../../../_metronic/_assets/js/components/cookie";
import { ActionButton } from "../controls/buttons/ActionButton";
import './ActionsBar.css';

const drawerWidthExpanded = 265;
const drawerWidthContracted = 70;

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    appBar: {
        top: '60px',
        right: '0px',
        width: `calc(100% - ${drawerWidthContracted}px)`,
        minWidth: '400px',
        zIndex: '50',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeIn,
            duration: '500',
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidthExpanded}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: '500',
        }),
    },
    appBarMobile: {
        top: '55px',
        left: '0px',
        width: '100%',
    },
    toolbar: {
        alignItems: 'center',
    },
    toolbarMobile: {
        minHeight: '130px',
    },
}));

export function ActionsBar({ pageTitlePrefix, pageTitle, cancelled, bookDetail, children }) {
    const classes = useStyles();
    const location = useLocation();

    function getAsideOpened() {
        const asideOpen = KTCookie.getCookie('kt_aside_toggle_state');
        return ((asideOpen === 'off') || (asideOpen === undefined));
    };

    const [drawerOpen, setDrawerOpen] = useState(getAsideOpened());
    const [drawerMobile, setDrawerMobile] = useState(KTUtil.isMobileDevice());

    useEffect(() => {
        setDrawerOpen(getAsideOpened());
    }, [location.pathname]);

    const asideToggleElement = KTLayoutAsideToggle.getElement();

    if (asideToggleElement) {
        asideToggleElement.onclick = function handleToggle() {
            setDrawerOpen(getAsideOpened());
        }
    } else {
        window.location.reload();
    }

    window.addEventListener('resize', function () {
        setDrawerMobile(KTUtil.isMobileDevice());
    });

    return (
        <div className={classes.root}>
            <AppBar className={clsx(classes.appBar, {
                [classes.appBarShift]: drawerOpen,
            }, {
                [classes.appBarMobile]: drawerMobile,
            })}
                position="fixed"
                color="inherit"
                elevation={0}>
                {(!drawerMobile) && (<Divider />)}
                <Toolbar className={clsx(classes.toolbar, {
                    [classes.toolbarMobile]: drawerMobile
                })}>
                    <Grid container justify="flex-start" alignItems="center">
                        <Grid item xs={12} sm={`${bookDetail ? 3 : 6}`} >
                            <Typography variant="h6" >
                                {(pageTitlePrefix) && (
                                    `${pageTitlePrefix} - `
                                )}
                                {(pageTitle) && (
                                    <FormattedMessage id={pageTitle} />
                                )}
                            </Typography>
                        </Grid>
                        {(cancelled === 1) && <Grid item xs={10} sm={2} container justify="flex-start" alignItems="center">
                            <ActionButton
                                cancelled={cancelled}
                                label="BOOKING_LIST.TABLE_HEAD.CANCELLED"
                            />
                        </Grid>}
                        <Grid item xs={10} sm={`${bookDetail ? cancelled === 1 ? 7 : 9 : 6}`} container justify="flex-end" alignItems="center">
                            {children}
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            {(drawerMobile) ? (
                <Box pb={16} />
            ) : (
                    <Box pb={7} />
                )}
        </div >
    );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    error: null,
    bills: null,
    refunds: null,
    msg:null,
};

export const billsSelectedSlice = createSlice({
    name: "billsSelected",
    initialState: initialState,
    reducers: {

        catchError: (state, action) => {
            state.loading = false;
            state.error = { ...action.payload.error };
        },

        startCall: (state, action) => {
            state.loading = true;
            state.error = null;
            state.data = null;
        },

        billsSelectedFetched: (state, action) => {
            const { msg,bills, refunds } = action.payload.response;
            state.loading = false;
            state.error = null;
            state.bills =  bills;
            state.refunds = refunds;
            state.msg =  msg;
        }

    }
});
